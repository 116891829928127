// React
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Formik
import { Formik, Form } from 'formik';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DragDrop } from '@uppy/react';

// API
import HelpPanel from 'components/forms/HelpPanel';
import { UPPY_SHOWN } from 'redux/actionTypes';
import { uploadToDataset } from '../../../redux/actions';
import handleApiError from '../../../services/Forms';

// Components
import { supportedDataTypesLabels, supportedDataTypesDetails } from '../../../services/Labels';

import { parseFilesForExistingDataset } from '../../../services/ParseFiles';

import UppyService from '../../../services/UppyService';

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import UploadingInfo from './UploadingInfo';

const UploadFilesModalForm = ({ dataset, onClose }) => {
    const dispatch = useDispatch();

    // We have to store files in a state because Formik won't handle them for us
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);

    // So just throw an undefined value to the field so Formik won't complain about the missing key
    const initialFormState = { files: undefined };

    const formikRef = useRef();

    useEffect(() => {
        dispatch({ type: UPPY_SHOWN, payload: true });
        const uppy = UppyService.getInstance();
        uppy.on('files-added', () => {
            setFiles(uppy.getFiles());
            // parseFiles(uppy.getFiles()).then((defaults) =>
            //     Object.keys(defaults).forEach((key) => {
            //         formikRef.current.setFieldValue(key, defaults[key] || initialFormState[key]);
            //     })
            // );
            parseFilesForExistingDataset(uppy.getFiles(), dataset).then((defaults) => {
                Object.keys(defaults).forEach((key) => {
                    formikRef.current.setFieldValue(key, defaults[key] || initialFormState[key]);
                });
                formikRef.current.setFieldError('parse', defaults.errors.parse);
                formikRef.current.setFieldError('files', defaults.errors.files);
            });
        });
        uppy.on('file-removed', () => setFiles(uppy.getFiles()));
        return () => {
            uppy.off('files-added');
            uppy.off('file-removed');
            dispatch({ type: UPPY_SHOWN, payload: false });
        };
    }, []);

    const validate = () => {
        const errors = {};
        if (!files || files.length === 0) {
            errors.files = 'field is required';
        }
        return errors;
    };

    const submitUpload = (values, helpers) => {
        // If we're here, validate has passed and we know files is not undefined
        UppyService.getInstance().on('restriction-failed', (file, error) => {
            helpers.setFieldError('files', error.message);
            formikRef.current.setSubmitting(false);
        });

        return dispatch(uploadToDataset(dataset))
            .then(() => {
                setUploading(dataset);
            })
            .catch((err) => {
                if (err.response && err.response.status === 409)
                    helpers.setFieldError('files', err.response.data.detail);
                else handleApiError(err, helpers);
            });
    };

    if (uploading) return <UploadingInfo dataset={uploading} onClose={onClose} />;

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormState}
            onSubmit={submitUpload}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formikRef}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    <ModalHeader>Upload to {dataset.name}</ModalHeader>
                    <ModalBody>
                        <HelpPanel>
                            Uploading new files will cause the dataset to be reprocessed. <br />
                            {supportedDataTypesLabels[dataset.datatype]} {supportedDataTypesDetails[dataset.datatype]}
                        </HelpPanel>
                        <DragDrop
                            className="form-group"
                            uppy={UppyService.getInstance()}
                            allowMultipleFiles
                            note="Drag a file or browse to upload"
                        />
                        <div className="form-group">
                            <div className="file-readout">
                                <span className="file-upload-list">{files.map((file) => file.name).join(', ')}</span>
                                {files.length !== 0 ? (
                                    <button
                                        type="button"
                                        className="pane-button danger"
                                        onClick={() => UppyService.clearFiles()}
                                    >
                                        <i className="fa fa-xmark" />
                                    </button>
                                ) : null}
                            </div>
                            {errors.parse
                                ? errors.parse.map((error) => <div className="error-text minor">{error}</div>)
                                : null}
                            <div className="error-text">{errors.files}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="pane-button large highlight" disabled={isSubmitting || !files}>
                            Upload <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default UploadFilesModalForm;
