/* eslint @typescript-eslint/no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */

import Dataset, {
    CopcProperties,
    EmptyDatasetProperties,
    LasDatasetProperties,
    MultiBandCogDatasetProperties,
    SeismicDatasetProperties,
    SingleBandCogDatasetProperties,
    TrackDatasetProperties,
} from 'types/Dataset';

import { LAYER_TYPES, LAYER_DATA_TYPES } from 'services/Constants';

import type Instance from '@giro3d/giro3d/core/Instance';
import LayerManager from 'giro3d_extensions/LayerManager';
import { Dispatch } from 'store';
import { SourceFile } from 'types/SourceFile';
import { HostView } from 'giro3d_extensions/layers/Layer';
import CogBuilder from './CogBuilder';
import OLGeojsonBuilder from './GeojsonBuilder';
import SeismicPlaneBuilder from './SeismicPlaneBuilder';
import { TracklineBuilder, PipelineBuilder, CableBuilder, BoreholeBuilder } from './LineLayerBuilder';
import LayerBuilder from './LayerBuilder';
import CopcBuilder from './CopcBuilder';

export type BuildContext = { instance: Instance; dispatch: Dispatch; layerManager: LayerManager; hostView: HostView };

type Builder = (
    dataset: Dataset,
    sourceFiles: SourceFile[]
) => LayerBuilder<
    | CopcProperties
    | LasDatasetProperties
    | TrackDatasetProperties
    | SeismicDatasetProperties
    | SingleBandCogDatasetProperties
    | MultiBandCogDatasetProperties
    | EmptyDatasetProperties
>;

type Selector<T extends LAYER_TYPES | LAYER_DATA_TYPES> = (type: T, context: BuildContext) => Builder;

function executeSelector<T extends LAYER_TYPES | LAYER_DATA_TYPES>(
    selector: Selector<T>,
    context: BuildContext,
    argument: T,
    dataset: Dataset,
    sourceFiles: SourceFile[]
) {
    return selector(argument, context)(dataset, sourceFiles);
}

const throwOnInvalidDatasetType = (dataset: Dataset) => {
    throw new Error(`Method 'build()' is not implemented for ${dataset.type}.`);
};

const throwOnInvalidDataType = (dataset: Dataset) => {
    throw new Error(`Method 'build()' is not implemented for ${dataset.type} of datatype ${dataset.datatype}.`);
};

const laserSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.RGBCOPC:
        case LAYER_DATA_TYPES.INTENSITYCOPC:
            return (dataset, sourceFiles) => new CopcBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const elevationLayerSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
            return (dataset, sourceFiles) => new CogBuilder(dataset, sourceFiles, context);
        case LAYER_DATA_TYPES.ELEVATIONCOPC:
            return (dataset, sourceFiles) => new CopcBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const waterColumnSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.INTENSITYCOPC:
            return (dataset, sourceFiles) => new CopcBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const colorLayerSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
        case LAYER_DATA_TYPES.MULTIBANDCOG:
            return (dataset, sourceFiles) => new CogBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const seismicSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.SEGY:
            return (dataset, sourceFiles) => new SeismicPlaneBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const cableSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.VECTOR:
            return (dataset, sourceFiles) => new CableBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const pipelineSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.VECTOR:
            return (dataset, sourceFiles) => new PipelineBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const trackSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.VECTOR:
            return (dataset, sourceFiles) => new TracklineBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const vectorSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.VECTOR:
            return (dataset, sourceFiles) => new OLGeojsonBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const emSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
        case LAYER_DATA_TYPES.MULTIBANDCOG:
            return (dataset, sourceFiles) => new CogBuilder(dataset, sourceFiles, context);
        case LAYER_DATA_TYPES.VECTOR:
            return (dataset, sourceFiles) => new TracklineBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const boreholeSelector: Selector<LAYER_DATA_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_DATA_TYPES.LAS:
            return (dataset, sourceFiles) => new BoreholeBuilder(dataset, sourceFiles, context);
        default:
            return (dataset, _sourceFiles) => throwOnInvalidDataType(dataset);
    }
};

const rootSelector: Selector<LAYER_TYPES> = (type, context) => {
    switch (type) {
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.CAMERA:
        case LAYER_TYPES.SAS:
            return (dataset, sourceFiles) =>
                executeSelector(colorLayerSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.LASER:
            return (dataset, sourceFiles) =>
                executeSelector(laserSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.BATHYMETRY:
        case LAYER_TYPES.HORIZON:
            return (dataset, sourceFiles) =>
                executeSelector(elevationLayerSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.WATER_COLUMN:
            return (dataset, sourceFiles) =>
                executeSelector(waterColumnSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.BOREHOLE:
            return (dataset, sourceFiles) =>
                executeSelector(boreholeSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.PIPELINE:
            return (dataset, sourceFiles) =>
                executeSelector(pipelineSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.CABLE:
            return (dataset, sourceFiles) =>
                executeSelector(cableSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.VECTOR:
            return (dataset, sourceFiles) =>
                executeSelector(vectorSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.TRACK:
            return (dataset, sourceFiles) =>
                executeSelector(trackSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.SEISMIC:
            return (dataset, sourceFiles) =>
                executeSelector(seismicSelector, context, dataset.datatype, dataset, sourceFiles);
        case LAYER_TYPES.EM:
            return (dataset, sourceFiles) =>
                executeSelector(emSelector, context, dataset.datatype, dataset, sourceFiles);
        default:
            return (dataset) => throwOnInvalidDatasetType(dataset);
    }
};

const createLayerBuilder = (dataset: Dataset, sourceFiles: SourceFile[], context: BuildContext) => {
    return executeSelector(rootSelector, context, dataset.type, dataset, sourceFiles);
};

export default createLayerBuilder;
