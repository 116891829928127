// React
import { useState, useEffect } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import { reorderDatasets, setClickedDataset, startObservationDetails } from 'redux/actions';
import * as layersSlice from 'redux/layers';
import { hasAttributes, hasSeismicPlane } from 'types/LayerState';
import { useEventBus } from 'EventBus';
import { shallowEqual } from 'react-redux';
import { LAYER_DATA_TYPES, LAYER_TYPES, PANE } from 'services/Constants';
import * as giro3dSlice from '../../../redux/giro3d';
import * as datasetsSlice from '../../../redux/datasets';

import Dial, { type DialSegment } from './Dial';
import DatasetSettingsPopover from '../datasetsMenu/DatasetSettingsPopover';

// Required to prevent the normal context menu opening
const noop = (e) => {
    if (e.button === 2) e.preventDefault();
};

const ContextMenu = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const toolState = useAppSelector(giro3dSlice.getContextMenu);

    const zScale = useAppSelector(giro3dSlice.getZScale);

    const order = useAppSelector(layersSlice.orderedIds, shallowEqual);
    const datasetId = toolState.dataset;
    const sourceFileId = toolState.sourceFile;
    const topDataset = useAppSelector(datasetsSlice.get(datasetId));
    const topSortable = order.includes(topDataset?.id);
    const topLayer = useAppSelector(layersSlice.get(datasetId));
    const topSourceFile = useAppSelector(layersSlice.getSourceFile(sourceFileId));

    const sourcefileVisible = useAppSelector(layersSlice.getSourceFileVisibility(topSourceFile));
    const sourcefileName = useAppSelector(datasetsSlice.getSourceFilename(datasetId, sourceFileId)) ?? 'file';

    const isVisible = sourcefileVisible;
    const name = sourcefileName;

    const [settingsOpen, setSettingsOpen] = useState(false);

    useEffect(() => {
        setSettingsOpen(undefined);
    }, [toolState]);

    const close = () => {
        eventBus.dispatch('close-context-menu');
    };

    const openDatasetsPopover = () => {
        setSettingsOpen(true);
    };

    const toggleVisibility = () => {
        dispatch(layersSlice.setSourceFileVisibility({ sourceFile: topSourceFile, value: !isVisible }));
    };

    const moveUp = () => {
        const index = order.indexOf(topDataset.id);
        [order[index], order[index + 1]] = [order[index + 1], order[index]];
        dispatch(datasetsSlice.reorderDatasets(order));
    };

    const moveDown = () => {
        const index = order.indexOf(topDataset.id);
        [order[index - 1], order[index]] = [order[index], order[index - 1]];
        dispatch(reorderDatasets(order));
    };

    const openSeismicView = () => {
        eventBus.dispatch('highlight-file', { dataset: datasetId, file: sourceFileId, scroll: true });
        dispatch(setClickedDataset(datasetId, sourceFileId));
        dispatch(datasetsSlice.setSeismicDataset({ dataset: datasetId, sourceFile: sourceFileId }));
        eventBus.dispatch('create-pane', {
            paneType: PANE.SEISMIC,
            showExisting: true,
        });
        eventBus.dispatch('create-dataset-pane', { paneType: PANE.INSPECTOR, datasetId, showExisting: true });
        close();
    };

    const copyCoordinates = () => {
        navigator.clipboard.writeText(`${toolState.point[0]}, ${toolState.point[1]}, ${toolState.point[2] / zScale}`);
        close();
    };

    const startInspectDataset = () => {
        eventBus.dispatch('create-dataset-pane', { paneType: PANE.INSPECTOR, datasetId, showExisting: true });
        close();
    };

    const openAttributeTable = () => {
        eventBus.dispatch('create-dataset-pane', { paneType: PANE.ATTRIBUTE_TABLE, datasetId, showExisting: true });
        close();
    };

    const openChart = () => {
        eventBus.dispatch('create-dataset-config-pane', {
            paneType: PANE.CHART,
            data: { datasetId, fileId: sourceFileId },
            showExisting: true,
        });
        close();
    };

    const viewSegment = () => {
        if (hasSeismicPlane(topLayer))
            return {
                icon: 'fa-eye',
                title: 'View in 2D',
                action: openSeismicView,
            };
        if (topDataset?.type === LAYER_TYPES.VECTOR)
            return {
                icon: 'fa-table',
                title: 'View in Table',
                action: openAttributeTable,
            };
        if (hasAttributes(topLayer) && topLayer.dataType === LAYER_DATA_TYPES.VECTOR)
            return {
                icon: 'fa-chart-line',
                title: 'View in Chart',
                action: openChart,
            };
        return {
            icon: 'fa-eye',
            title: 'No View Options',
        };
    };

    const createObservation = () => {
        dispatch(startObservationDetails([toolState.point[0], toolState.point[1], toolState.point[2] / zScale]));
        close();
    };

    const buttons: DialSegment[] = [
        {
            icon: 'fa-copy',
            title: 'Copy coordinates',
            action: copyCoordinates,
        },
        {
            icon: 'fa-arrow-up',
            title: 'Move up',
            action: topSortable && order[order.length - 1] !== datasetId ? moveUp : undefined,
        },
        {
            icon: 'fa-bars',
            title: `${topDataset?.name || 'Dataset'} settings`,
            action: topLayer ? openDatasetsPopover : undefined,
            id: 'dataset-options',
        },
        {
            icon: 'fa-arrow-down',
            title: 'Move down',
            action: topSortable && order[0] !== datasetId ? moveDown : undefined,
        },
        {
            icon: isVisible ? 'fa-toggle-on' : 'fa-toggle-off',
            title: isVisible ? `Hide ${name}` : `Show ${name}`,
            action: topLayer ? toggleVisibility : undefined,
            id: 'sourceFile-options',
        },
        {
            icon: 'fa-comment',
            title: 'Create Observation',
            action: createObservation,
        },
        {
            icon: 'fa-info-circle',
            title: `Inspect ${topDataset?.name || 'dataset'}`,
            action: topLayer ? startInspectDataset : undefined,
        },
        viewSegment(),
    ];

    const popover = () => (
        // Preventing clicking on the menu closing it
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className="select-tool"
            style={{ 'top': `${toolState.offset.y}px`, 'left': `${toolState.offset.x}px` }}
            onClick={noop}
            onDoubleClick={noop}
            onContextMenu={noop}
        >
            <Dial buttons={buttons} />
            {topDataset && (
                <DatasetSettingsPopover
                    dataset={topDataset}
                    target="dataset-options"
                    isOpen={settingsOpen}
                    onToggle={() => setSettingsOpen(false)}
                />
            )}
        </div>
    );

    return toolState.open ? popover() : null;
};

const ContextMenuWrapper = () => {
    const { open } = useAppSelector(giro3dSlice.getContextMenu, (a, b) => a.open === b.open);
    if (!open) return null;
    return <ContextMenu />;
};

export default ContextMenuWrapper;
