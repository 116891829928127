import { LAYER_TYPES, LAYER_DATA_TYPES, LAYER_STATES, SOURCE_FILE_STATES } from './Constants';

export const supportedTypesLabels = {
    [LAYER_TYPES.ATTRIBUTE]: 'Attribute',
    [LAYER_TYPES.BACKSCATTER]: 'Backscatter',
    [LAYER_TYPES.BATHYMETRY]: 'Bathymetry',
    [LAYER_TYPES.HORIZON]: 'Horizon',
    [LAYER_TYPES.WATER_COLUMN]: 'Water column',
    [LAYER_TYPES.BOREHOLE]: 'Borehole',
    [LAYER_TYPES.CABLE]: 'Cable',
    [LAYER_TYPES.PIPELINE]: 'Pipeline',
    [LAYER_TYPES.TRACK]: 'Trackline',
    [LAYER_TYPES.SAS]: 'SAS',
    [LAYER_TYPES.SEISMIC]: 'Seismic',
    [LAYER_TYPES.VECTOR]: 'Vector',
    [LAYER_TYPES.EM]: 'EM',
    [LAYER_TYPES.CAMERA]: 'Camera',
    [LAYER_TYPES.LASER]: 'Laser',
    [LAYER_TYPES.UNKNOWN]: 'Unknown',
};

// TODO: get this from back-end (supportedDatasetType)
export const supportedDataTypes = {
    [LAYER_TYPES.BACKSCATTER]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG],
    [LAYER_TYPES.BATHYMETRY]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.ELEVATIONCOPC],
    [LAYER_TYPES.HORIZON]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.ELEVATIONCOPC],
    [LAYER_TYPES.BOREHOLE]: [LAYER_DATA_TYPES.LAS],
    [LAYER_TYPES.CABLE]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.PIPELINE]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.TRACK]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.SAS]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG],
    [LAYER_TYPES.SEISMIC]: [LAYER_DATA_TYPES.SEGY],
    [LAYER_TYPES.VECTOR]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.ATTRIBUTE]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG],
    [LAYER_TYPES.EM]: [LAYER_DATA_TYPES.VECTOR, LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG],
    [LAYER_TYPES.CAMERA]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG],
    [LAYER_TYPES.WATER_COLUMN]: [LAYER_DATA_TYPES.INTENSITYCOPC],
    [LAYER_TYPES.LASER]: [LAYER_DATA_TYPES.RGBCOPC],
};

export const supportedDataTypesLabels = {
    [LAYER_DATA_TYPES.SINGLEBANDCOG]: 'Single-band GeoTIFF',
    [LAYER_DATA_TYPES.MULTIBANDCOG]: 'Multi-band GeoTIFF',
    [LAYER_DATA_TYPES.VECTOR]: 'GeoJSON/Shapefile/h5',
    [LAYER_DATA_TYPES.SEGY]: 'SEG-Y',
    [LAYER_DATA_TYPES.LAS]: 'Log ASCII standard (LAS)',
    [LAYER_DATA_TYPES.ELEVATIONCOPC]: 'XYZ file',
    [LAYER_DATA_TYPES.INTENSITYCOPC]: 'XYZ file with Intensity attribute',
    [LAYER_DATA_TYPES.RGBCOPC]: 'XYZ file with RGB attributes',
};

export const supportedDataTypesDetails = {
    [LAYER_DATA_TYPES.SINGLEBANDCOG]: 'Expects single-band .tiff files.',
    [LAYER_DATA_TYPES.MULTIBANDCOG]: 'Expects multi-band .tiff files.',
    [LAYER_DATA_TYPES.VECTOR]: 'Expects .geojson files, or a zipped .shp file (with .shx, .dbf, .prj etc.).',
    [LAYER_DATA_TYPES.SEGY]: 'Expects SEG-Y file(s). Will be reprocessed to VDS.',
    [LAYER_DATA_TYPES.LAS]: 'Expects .las files.',
    [LAYER_DATA_TYPES.ELEVATIONCOPC]: 'Expects XYZ files with no attributes.',
    [LAYER_DATA_TYPES.INTENSITYCOPC]: 'Expects XYZ files with an intensity attribute.',
    [LAYER_DATA_TYPES.RGBCOPC]: 'Expects XYZ files with RGB attributes.',
};

export const layerStateLabels = {
    [LAYER_STATES.ACTIVE]: 'Active',
    [LAYER_STATES.VALIDATION_FAILED]: 'Validation Failed',
    [LAYER_STATES.CONVERSION_FAILED]: 'Conversion Failed',
    [LAYER_STATES.CONVERTING]: 'Converting',
    [LAYER_STATES.DELETING]: 'Deleting',
    [LAYER_STATES.DELETION_FAILED]: 'Deletion Failed',
    [LAYER_STATES.EMPTY]: 'Empty',
    [LAYER_STATES.LOADED]: 'Loaded',
    [LAYER_STATES.LOADING]: 'Loading',
    [LAYER_STATES.LOADING_FAILED]: 'Loading Failed',
    [LAYER_STATES.UPLOADING]: 'Uploading',
    [SOURCE_FILE_STATES.VALIDATING]: 'Validating',
    [SOURCE_FILE_STATES.INGESTED]: 'Ingested',
    [SOURCE_FILE_STATES.INGESTING]: 'Igesting',
    [SOURCE_FILE_STATES.INGESTION_FAILED]: 'Ingestion Failed',
};
