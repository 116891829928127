import { SOURCE_FILE_STATES } from 'services/Constants';
import type { LineString, MultiPoint, MultiPolygon, Point, Polygon } from 'geojson';
import { GeometryWithCRS, SourceFileId, Url } from './common';
import {
    SingleBandCogSourceFileProperties,
    SeismicSourceFileProperties,
    MultiBandCogSourceFileProperties,
    EmptySourceFileProperties,
    ElevationCopcSourceFileProperties,
    RGBCopcSourceFileProperties,
    IntensityCopcSourceFileProperties,
    LasSourceFileProperties,
    TrackSourceFileProperties,
} from './Dataset';

export type SourceFileGeometry = GeometryWithCRS<MultiPolygon | Point | Polygon | MultiPoint | LineString>;

export type SourceFile = {
    id: SourceFileId;
    source?: string;
    state: SOURCE_FILE_STATES;
    state_msg?: string;

    geometry?: SourceFileGeometry;

    links: {
        download: Url;
    };

    properties:
        | LasSourceFileProperties
        | TrackSourceFileProperties
        | ElevationCopcSourceFileProperties
        | RGBCopcSourceFileProperties
        | IntensityCopcSourceFileProperties
        | SingleBandCogSourceFileProperties
        | MultiBandCogSourceFileProperties
        | SeismicSourceFileProperties
        | EmptySourceFileProperties;
};

export function getBaseName(file: SourceFile) {
    if (!file?.source) {
        return 'unknown';
    }
    return file.source.replace(/^.*[\\/]/, '');
}
