import { fromBlob } from 'geotiff';
import { UppyFile } from '@uppy/core';
import JSZip from 'jszip';
import Dataset, { SingleBandCogDatasetProperties, MultiBandCogDatasetProperties } from 'types/Dataset';
import { isCOPCType, isRasterType, LAYER_DATA_TYPES, LAYER_TYPES, PROCESSABLE_EXTENSIONS } from './Constants';

const RESOLUTION_ALLOWANCE = 0.05;

type parseResult = {
    name: string;
    datatype: LAYER_DATA_TYPES;
    type: LAYER_TYPES;
    projection: string;
    errors: {
        parse: string[];
        files: string;
    };
};

async function readBandsFromGeotiff(blob) {
    try {
        const image = await (await fromBlob(blob)).getImage();
        return image.getSamplesPerPixel();
    } catch (error) {
        console.error('Error reading GeoTIFF:', error);
        return undefined;
    }
}

async function readResolutionFromGeotiff(blob) {
    try {
        const image = await (await fromBlob(blob)).getImage();
        return image.getResolution().map((res) => Math.abs(res));
    } catch (error) {
        console.error('Error reading GeoTIFF:', error);
        return undefined;
    }
}

async function extractExtensionsFromZip(f): Promise<string[]> {
    return new Promise((resolve, reject) => {
        JSZip.loadAsync(f).then(
            (zip) =>
                resolve(Object.values(zip.files).map((file) => file.name.substring(file.name.lastIndexOf('.') + 1))),
            (e) => reject(e.message)
        );
    });
}

async function determineFileDatatype(file: File, extension: string): Promise<LAYER_DATA_TYPES> {
    return new Promise((resolve) => {
        switch (extension) {
            case 'tif':
            case 'tiff':
                resolve(
                    readBandsFromGeotiff(file).then((bands) =>
                        bands === 1 ? LAYER_DATA_TYPES.SINGLEBANDCOG : LAYER_DATA_TYPES.MULTIBANDCOG
                    )
                );
                break;
            case 'h5':
            case 'hdf5':
            case 'json':
            case 'geojson':
                resolve(LAYER_DATA_TYPES.VECTOR);
                break;
            case 'las':
                resolve(LAYER_DATA_TYPES.LAS);
                break;
            // These are pointclouds but we don't parse to know which COPC type
            // case 'xyz':
            //     resolve(LAYER_DATA_TYPES.INTENSITYCOPC);
            //     resolve(LAYER_DATA_TYPES.RGBCOPC);
            //     resolve(LAYER_DATA_TYPES.ELEVATIONCOPC);
            //     break;
            case 'sgy':
            case 'segy':
                resolve(LAYER_DATA_TYPES.SEGY);
                break;
            case 'zip': {
                resolve(
                    extractExtensionsFromZip(file).then((extensions) => {
                        if (extensions.includes('shx')) return LAYER_DATA_TYPES.VECTOR;
                        return undefined;
                    })
                );
                break;
            }
            default:
                resolve(undefined);
        }
    });
}

function determineDatatype(files: UppyFile[]): Promise<LAYER_DATA_TYPES> {
    const datatypes = [
        ...new Set(
            files.map((file) => {
                const f = file.data as File;
                const extension = file.extension;
                return determineFileDatatype(f, extension);
            })
        ),
    ];
    return Promise.all(datatypes).then((results) => {
        const uniqueDatatypes = [...new Set(results)];
        if (uniqueDatatypes.length === 1) return uniqueDatatypes[0];
        return Promise.reject(new Error('Files have different datatypes'));
    });
}

function determineType(datatype: LAYER_DATA_TYPES): Promise<LAYER_TYPES> {
    return new Promise((resolve) => {
        switch (datatype) {
            case LAYER_DATA_TYPES.SEGY:
                resolve(LAYER_TYPES.SEISMIC);
                break;
            case LAYER_DATA_TYPES.LAS:
                resolve(LAYER_TYPES.BOREHOLE);
                break;
            default:
                resolve(undefined);
        }
    });
}

async function readCRSFromGeotiff(blob): Promise<string> {
    return new Promise((resolve, reject) => {
        fromBlob(blob)
            .then((tiff) =>
                tiff.getImage().then((image) => {
                    const geoKeys = image.getGeoKeys();
                    // Check if the projection keys exist and return undefined if not found
                    if (geoKeys.ProjectedCSTypeGeoKey || geoKeys.GeographicTypeGeoKey) {
                        resolve(String(geoKeys.ProjectedCSTypeGeoKey || geoKeys.GeographicTypeGeoKey));
                    }
                    resolve(undefined); // Return undefined if no projection is found
                })
            )
            .catch((e) => reject(new Error(`Geotiff error: ${e.message}`)));
    });
}

function determineFileProjection(file: File, extension: string): Promise<string> {
    return new Promise((resolve, reject) => {
        switch (extension) {
            case 'geojson': {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (event) => {
                    if (event.currentTarget instanceof FileReader) {
                        const result = String(event.currentTarget.result);
                        const crsProperty = JSON.parse(result).crs?.properties?.name;
                        if (crsProperty) {
                            const parts = crsProperty.split(':');
                            resolve(parts[parts.length - 1]);
                        }
                    }
                    resolve('3857'); // GeoJson standard is 3857, defined CRS is an obselete feature.
                };
                break;
            }
            case 'tiff':
            case 'tif':
                readCRSFromGeotiff(file)
                    .then((crs) => {
                        resolve(crs);
                    })
                    .catch((e) => {
                        reject(e);
                    });
                break;
            default:
                resolve(undefined);
        }
    });
}

function determineProjection(files: UppyFile[]): Promise<string> {
    const projections = files.map((file) => {
        const f = file.data as File;
        const extension = file.extension;
        return determineFileProjection(f, extension);
    });
    return Promise.all(projections).then((results) => {
        const uniqueProjections = [...new Set(results)];
        if (uniqueProjections.length === 1) return uniqueProjections[0];
        return Promise.reject(new Error('Files have different projections'));
    });
}

function validateResolutions(files: UppyFile[]): Promise<void> {
    const resolutions = files.map((file) => {
        const f = file.data as File;
        return readResolutionFromGeotiff(f);
    });

    return Promise.all(resolutions).then((results) => {
        if (!results[0]) return Promise.reject(new Error('Could not read resolution'));

        const meanResolution = results.reduce(
            (acc, res) => {
                if (!res) return acc;
                return [acc[0] + res[0], acc[1] + res[1]];
            },
            [0, 0]
        );
        meanResolution[0] /= results.length;
        meanResolution[1] /= results.length;

        const areSimilar = results.every(
            (res) =>
                res &&
                Math.abs(res[0] - meanResolution[0]) <= RESOLUTION_ALLOWANCE * meanResolution[0] &&
                Math.abs(res[1] - meanResolution[1]) <= RESOLUTION_ALLOWANCE * meanResolution[1]
        );

        if (areSimilar) return Promise.resolve();
        return Promise.reject(new Error('Files have different resolutions'));
    });
}

async function parseFiles(files: UppyFile[]): Promise<parseResult> {
    const result: parseResult = {
        name: undefined,
        datatype: undefined,
        type: undefined,
        projection: undefined,
        errors: {
            parse: [],
            files: undefined,
        },
    };
    try {
        const firstFile = files[0].data as File;
        const lastDotIndex = firstFile.name.lastIndexOf('.');
        result.name = lastDotIndex !== -1 ? firstFile.name.substring(0, lastDotIndex) : firstFile.name;

        await determineDatatype(files)
            .then((datatype) => {
                result.datatype = datatype;
            })
            .catch((e) => result.errors.parse.push(`Datatype error: ${e.message}`));
        await determineType(result.datatype)
            .then((type) => {
                result.type = type;
            })
            .catch((e) => result.errors.parse.push(`Type error: ${e.message}`));
        await determineProjection(files)
            .then((projection) => {
                result.projection = projection;
            })
            .catch((e) => result.errors.parse.push(`Projection error: ${e.message}`));

        if (isRasterType(result.datatype))
            await validateResolutions(files).catch((e) => result.errors.parse.push(`Resolution error: ${e.message}`));
    } catch (e) {
        result.errors.parse.push(e);
    }

    const extensions = [...new Set(files.map((file) => file.extension))];
    if (!result.datatype && extensions.length !== 1) result.errors.files = 'Files have different extensions';
    if (!extensions.every((ext) => PROCESSABLE_EXTENSIONS.includes(ext)))
        result.errors.files = `File is not processable: ${extensions.filter((ext) => !PROCESSABLE_EXTENSIONS.includes(ext)).join(', ')}`;

    return result;
}

type existingParseResult = {
    errors: {
        parse: string[];
        files: string;
    };
};

function matchDatatype(files: UppyFile[], dataset: Dataset): Promise<void> {
    // COPC type can't be determined from the file but all files must be XYZ
    if (isCOPCType(dataset.datatype)) {
        const extensions = [...new Set(files.map((file) => file.extension))];
        if (extensions.length === 1 && extensions[0] === 'xyz') return Promise.resolve();
        return Promise.reject(new Error('Files have different datatypes'));
    }

    const datatypes = files.map((file) => {
        const f = file.data as File;
        const extension = file.extension;
        return determineFileDatatype(f, extension);
    });
    return Promise.all(datatypes).then((results) => {
        const match = results.every((res) => res && res === dataset.datatype);
        if (match) return Promise.resolve();
        return Promise.reject(new Error('Files have different datatypes'));
    });
}

function matchProjection(files: UppyFile[], dataset: Dataset): Promise<void> {
    const projections = files.map((file) => {
        const f = file.data as File;
        const extension = file.extension;
        return determineFileProjection(f, extension);
    });
    return Promise.all(projections).then((results) => {
        const match = results.every((res) => res === undefined || (res && res === String(dataset.projection)));
        if (match) return Promise.resolve();
        return Promise.reject(new Error('Files have different projections'));
    });
}

function matchResolution(files: UppyFile[], dataset: Dataset): Promise<void> {
    const datasetResolution = (dataset.properties as SingleBandCogDatasetProperties | MultiBandCogDatasetProperties)
        .avg_resolution;
    const resolutions = files.map((file) => {
        const f = file.data as File;
        return readResolutionFromGeotiff(f);
    });

    return Promise.all(resolutions).then((results) => {
        if (!results[0]) return Promise.reject(new Error('Could not read resolution'));

        const areSimilar = results.every(
            (res) =>
                res &&
                Math.abs(res[0] - datasetResolution[0]) <= RESOLUTION_ALLOWANCE * datasetResolution[0] &&
                Math.abs(res[1] - datasetResolution[1]) <= RESOLUTION_ALLOWANCE * datasetResolution[1]
        );

        if (areSimilar) return Promise.resolve();
        return Promise.reject(new Error('Files have different resolutions'));
    });
}

export async function parseFilesForExistingDataset(files: UppyFile[], dataset: Dataset): Promise<existingParseResult> {
    const result: existingParseResult = {
        errors: {
            parse: [],
            files: undefined,
        },
    };
    try {
        await matchDatatype(files, dataset).catch((e) => result.errors.parse.push(`Datatype error: ${e.message}`));
        await matchProjection(files, dataset).catch((e) => result.errors.parse.push(`Projection error: ${e.message}`));
        if (
            isRasterType(dataset.datatype) &&
            (dataset.properties as SingleBandCogDatasetProperties | MultiBandCogDatasetProperties).avg_resolution
        )
            await matchResolution(files, dataset).catch((e) =>
                result.errors.parse.push(`Resolution error: ${e.message}`)
            );
    } catch (e) {
        result.errors.parse.push(e);
    }

    const extensions = [...new Set(files.map((file) => file.extension))];
    if (!extensions.every((ext) => PROCESSABLE_EXTENSIONS.includes(ext)))
        result.errors.files = `File is not processable: ${extensions.filter((ext) => !PROCESSABLE_EXTENSIONS.includes(ext)).join(', ')}`;

    return result;
}

export default parseFiles;
