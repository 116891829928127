import { useDispatch } from 'react-redux';
import { HasColorimetry, LayerState } from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Slider from 'components/dropdown/Slider';
import Button from 'components/dropdown/Button';
import { DEFAULT_LAYER_SETTINGS } from 'services/Constants';

export type Props = { layer: LayerState & HasColorimetry };

function ColorimetrySettings(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();

    const brightness = useAppSelector(layersSlice.getBrightness(layer));
    const contrast = useAppSelector(layersSlice.getContrast(layer));
    const saturation = useAppSelector(layersSlice.getSaturation(layer));

    function changeLayerBrightness(value: number) {
        dispatch(layersSlice.setBrightness({ layer, value }));
    }

    function changeLayerContrast(value: number) {
        dispatch(layersSlice.setContrast({ layer, value }));
    }

    function changeLayerSaturation(value: number) {
        dispatch(layersSlice.setSaturation({ layer, value }));
    }

    return (
        <>
            <Button
                title="Reset"
                icon="fas fa-undo"
                onClick={() => {
                    changeLayerBrightness(DEFAULT_LAYER_SETTINGS.BRIGHTNESS);
                    changeLayerContrast(DEFAULT_LAYER_SETTINGS.CONTRAST);
                    changeLayerSaturation(DEFAULT_LAYER_SETTINGS.SATURATION);
                }}
            />
            <Slider
                title="Brightness"
                icon="fas fa-sun"
                min={-100}
                max={100}
                step={1}
                value={brightness * 100}
                onChange={(v) => changeLayerBrightness(v / 100)}
                unit="%"
            />
            <Slider
                title="Contrast"
                icon="fas fa-adjust"
                min={0}
                max={200}
                step={1}
                value={contrast * 100}
                onChange={(v) => changeLayerContrast(v / 100)}
                unit="%"
            />
            <Slider
                title="Saturation"
                icon="fas fa-palette"
                min={0}
                max={200}
                step={1}
                value={saturation * 100}
                onChange={(v) => changeLayerSaturation(v / 100)}
                unit="%"
            />
            <hr />
        </>
    );
}

export default ColorimetrySettings;
