import { Button, UncontrolledTooltip } from 'reactstrap';
import * as datasetsSlice from 'redux/datasets';
import { LAYER_STATES } from 'services/Constants';
import { useAppSelector } from 'store';
import Dataset from 'types/Dataset';
import { useState } from 'react';
import { useMountEffect } from 'components/utils';
import { useEventBus } from 'EventBus';
import { DatasetId } from 'types/common';

export type Props = {
    id: string;
    dataset: Dataset;
};

const datasetListItemStatus = (dataset: Dataset) => {
    switch (dataset.state) {
        case LAYER_STATES.EMPTY:
            return 'Dataset has no data.';
        case LAYER_STATES.UPLOADING:
            return 'Dataset is currently being uploaded.';
        case LAYER_STATES.VALIDATION_FAILED:
            return `Data validation has failed: ${dataset.state_msg}`;
        case LAYER_STATES.CONVERTING:
            return 'Dataset is currently being processed. It will be automatically loaded once the processing is done.';
        case LAYER_STATES.CONVERSION_FAILED:
            return `Data processing has failed: ${dataset.state_msg}`;
        case LAYER_STATES.DELETING:
            return 'Dataset is being deleted';
        case LAYER_STATES.DELETION_FAILED:
            return `Data deletion has failed: ${dataset.state_msg}`;
        case LAYER_STATES.LOADING:
            return 'Dataset is currently being loaded into the view.';
        case LAYER_STATES.LOADING_FAILED:
            return `Loading data has failed: ${dataset.loadFailureReason}`;
        default:
            return 'Unknown state';
    }
};

const DatasetIndicator = (props: Props) => {
    const { id, dataset } = props;
    const eventBus = useEventBus();

    const [loading, setLoading] = useState(false);

    const state = useAppSelector(datasetsSlice.getDatasetState(dataset.id));

    const onStatusUpdated = (arg: { id: DatasetId; loading: boolean }) => {
        if (dataset.id === arg.id) {
            setLoading(arg.loading);
        }
    };

    const mount = () => {
        eventBus.subscribe('dataset-loading-status-updated', onStatusUpdated);
    };

    const unmount = () => {
        eventBus.unsubscribe('dataset-loading-status-updated', onStatusUpdated);
    };

    useMountEffect(mount, unmount);

    let icon: string;
    switch (state) {
        case LAYER_STATES.EMPTY:
            icon = 'fas fa-file';
            break;
        case LAYER_STATES.UPLOADING:
            icon = 'fas fa-file-upload';
            break;
        case LAYER_STATES.CONVERTING:
            icon = 'fas fa-cog fa-spin';
            break;
        case LAYER_STATES.CONVERSION_FAILED:
            icon = 'fas fa-exclamation-triangle';
            break;
        case LAYER_STATES.DELETING:
            icon = 'fas fa-trash';
            break;
        case LAYER_STATES.DELETION_FAILED:
            icon = 'fas fa-exclamation-triangle';
            break;
        case LAYER_STATES.LOADED:
        case LAYER_STATES.ACTIVE:
            icon = loading ? 'fas fa-spinner fa-pulse' : '';
            break;
        case LAYER_STATES.LOADING:
            icon = 'fas fa-spinner fa-pulse';
            break;
        case LAYER_STATES.LOADING_FAILED:
            icon = 'fas fa-exclamation-triangle';
            break;
        default:
            icon = 'fas fa-exclamation-triangle';
            break;
    }

    return (
        <>
            <Button className="borderless indicator" id={`dataset-indicator-${id}`} hidden={!icon}>
                <i className={icon} />
            </Button>
            <UncontrolledTooltip placement="bottom" target={`dataset-indicator-${id}`} fade={false} hideArrow>
                {datasetListItemStatus(dataset)}
            </UncontrolledTooltip>
            <Button className="borderless indicator" id={`dataset-warning-${id}`} hidden={!dataset.client_state_msg}>
                <i className="fas fa-triangle-exclamation icon-yellow no-hover" />
            </Button>
            <UncontrolledTooltip placement="bottom" target={`dataset-warning-${id}`} fade={false} hideArrow>
                {dataset.client_state_msg}
            </UncontrolledTooltip>
        </>
    );
};

export default DatasetIndicator;
