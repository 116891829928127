import * as layersSlice from 'redux/layers';

import Dataset from 'types/Dataset';
import {
    canShowInMinimap,
    hasAmplitude,
    hasArrows,
    hasColoringMode,
    hasDraping,
    hasMasks,
    hasRadius,
    isPointCloud,
    hasSeismicPlane,
    hasVectorStyle,
    hasBorehole,
    hasAttributes,
    hasFootprint,
    hasDataPointMode,
    hasOpacity,
    hasColorimetry,
} from 'types/LayerState';

// Components
import { useAppSelector } from 'store';
import DrapingSettings from './datasetSettings/DrapingSettings';
import MinimapVisibilitySettings from './datasetSettings/MinimapVisibilitySettings';
import { ColorModeSetting } from './datasetSettings/ColoringSettings';
import OpacitySetting from './datasetSettings/OpacitySettings';
import MaskSettings from './datasetSettings/MaskSettings';
import PointCloudSettings from './datasetSettings/PointCloudSettings';
import RadiusSetting from './datasetSettings/RadiusSetting';
import SeismicSettings from './datasetSettings/SeismicSettings';
import ArrowSettings from './datasetSettings/ArrowSettings';
import AmplitudeSettings from './datasetSettings/AmplitudeSettings';
import VectorSettings from './datasetSettings/VectorSettings';
import BoreholeSettings from './datasetSettings/BoreholeSettings';
import FootprintSettings from './datasetSettings/FootprintSettings';
import DataPointModeSettings from './datasetSettings/DataPointModeSettings';
import ColorimetrySettings from './datasetSettings/ColorimetrySettings';

export type Props = {
    dataset: Dataset;
};

const DatasetListItemSettings = (props: Props) => {
    const { dataset } = props;
    const layer = useAppSelector(layersSlice.get(dataset.id));

    return (
        <ul>
            {canShowInMinimap(layer) ? <MinimapVisibilitySettings layer={layer} /> : null}
            {hasOpacity(layer) ? <OpacitySetting layer={layer} /> : null}
            {hasColorimetry(layer) ? <ColorimetrySettings layer={layer} /> : null}
            {hasDraping(layer) ? <DrapingSettings layer={layer} /> : null}
            {hasRadius(layer) ? <RadiusSetting layer={layer} /> : null}
            {hasBorehole(layer) && hasColoringMode(layer) ? <BoreholeSettings layer={layer} /> : null}
            {isPointCloud(layer) ? <PointCloudSettings layer={layer} /> : null}
            {hasFootprint(layer) ? <FootprintSettings layer={layer} /> : null}
            {hasColoringMode(layer) ? (
                <>
                    <ColorModeSetting layer={layer} />
                    {hasDataPointMode(layer) && hasAttributes(layer) ? <DataPointModeSettings layer={layer} /> : null}
                    {hasAmplitude(layer) && hasAttributes(layer) ? <AmplitudeSettings layer={layer} /> : null}
                </>
            ) : null}
            {hasMasks(layer) ? <MaskSettings layerState={layer} /> : null}
            {hasSeismicPlane(layer) ? <SeismicSettings layer={layer} /> : null}
            {hasArrows(layer) ? <ArrowSettings layer={layer} /> : null}
            {hasVectorStyle(layer) ? <VectorSettings layer={layer} /> : null}
        </ul>
    );
};

export default DatasetListItemSettings;
