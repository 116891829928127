import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Spinner, Table, ModalHeader } from 'reactstrap';
import { SourceFile, getBaseName } from 'types/SourceFile';
import Dataset from 'types/Dataset';
import { useAppDispatch, useAppSelector } from 'store';
import StateIndicator from 'components/StateIndicator';
import { useMountEffect } from 'components/utils';
import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';
import * as layersSlice from 'redux/layers';
import { LAYER_STATES } from 'services/Constants';
import { SourceFileId } from 'types/common';
import ApiErrors from 'services/ApiErrors';
import { showError } from '../../../redux/actions';
import DosApi from '../../../services/DosApi';

export type Props = {
    dataset: Dataset;
};

const SourceFileTable = (props: Props) => {
    const { dataset } = props;
    const dispatch = useAppDispatch();
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(dataset.id));
    const clickedSourceFile = useAppSelector(giro3dSlice.getSelection)?.sourceFileId;

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    useMountEffect(() => {
        if (!sourcefiles)
            DosApi.fetchDatasetSourcefiles(dataset.id)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    });

    const [modalDelete, setModalDelete] = useState(false);
    const [currentDeletedItem, setCurrentDeletedItem] = useState<SourceFile>(undefined);
    const [batchDeleteList, setBatchDeleteList] = useState<SourceFileId[]>();
    const [batchDeleteState, setBatchDeleteState] = useState(null);
    const [errorText, setErrorText] = useState();

    const addToBatch = (sourcefileId: SourceFileId) => {
        setBatchDeleteList([...batchDeleteList, sourcefileId]);
    };
    const removeFromBatch = (sourcefileId: SourceFileId) => {
        setBatchDeleteList(batchDeleteList.filter((s) => s !== sourcefileId));
    };

    const toggleDelete = (item: SourceFile) => {
        setCurrentDeletedItem(item);
        setModalDelete(!modalDelete);
    };

    const deleteFiles = (files: SourceFile[]) => {
        return DosApi.deleteDatasetSourcefiles(
            dataset.id,
            files.map((f) => f.id)
        )
            .then(() => {
                dispatch(
                    datasetsSlice.removeDatasetSourceFiles({
                        datasetId: dataset.id,
                        sourceFileIds: files.map((sf) => sf.id),
                    })
                );
                dispatch(
                    layersSlice.deleteSourcefiles({
                        dataset,
                        sourceFiles: files,
                    })
                );
                dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.CONVERTING }));
            })
            .catch((err) => showError(dispatch, err))
            .finally(() => {
                setModalDelete(false);
                setCurrentDeletedItem(undefined);
            });
    };

    const batchDeleteModalContent = () => {
        switch (batchDeleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <span className="small-modal-text">
                                {batchDeleteList.length} source files will be removed from Argeo SCOPE.
                            </span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setBatchDeleteState(DELETE.DELETING);
                                    deleteFiles(batchDeleteList.map((id) => sourcefiles.find((file) => file.id === id)))
                                        .then(async () => {
                                            setBatchDeleteState(DELETE.CONFIRMATION);
                                            setBatchDeleteList(undefined);
                                            setTimeout(() => setBatchDeleteState(null), 2000);
                                        })
                                        .catch((err) => {
                                            setErrorText(ApiErrors.getErrorMessage(err));
                                            setBatchDeleteState(DELETE.ERROR);
                                        });
                                }}
                            >
                                Yes, Delete these Source Files
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => setBatchDeleteState(null)}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting datasets...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Datasets deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => setBatchDeleteState(null)}
                            >
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <ModalHeader>
                Manage {dataset.name} source files
                <button
                    type="button"
                    className="pane-button large highlight"
                    id="batch-delete"
                    onClick={() => setBatchDeleteList(batchDeleteList === undefined ? [] : undefined)}
                    hidden={batchDeleteList !== undefined}
                >
                    Batch Delete
                    <i className="fas fa-dumpster" />
                </button>
            </ModalHeader>
            <ModalBody>
                {sourcefiles === null ? (
                    <Spinner animation="border" />
                ) : (
                    <Table size="sm" className="content-middle">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th>State</th>
                                <th>{batchDeleteList === undefined ? 'Actions' : 'Delete'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourcefiles.map((file) => (
                                <tr
                                    key={`sourcefileTable-row-${file.id}`}
                                    className={`${clickedSourceFile === file.id ? 'highlighted' : ''}`}
                                >
                                    <td>{getBaseName(file)}</td>
                                    <td>
                                        <StateIndicator id={file.id} state={file.state} state_msg={file.state_msg} />
                                    </td>
                                    <td>
                                        {batchDeleteList === undefined ? (
                                            <Button
                                                className="borderless red"
                                                id={`sourcefilebtn-delete-${file.id}`}
                                                title={`Delete ${getBaseName(file)}`}
                                                onClick={() => toggleDelete(file)}
                                            >
                                                <i className="fal fa-trash-alt" />
                                            </Button>
                                        ) : (
                                            <label className="checkbox" htmlFor={`batch-${file.id}`}>
                                                <input
                                                    type="checkbox"
                                                    id={`batch-${file.id}`}
                                                    checked={batchDeleteList.includes(file.id)}
                                                    onChange={() =>
                                                        batchDeleteList.includes(file.id)
                                                            ? removeFromBatch(file.id)
                                                            : addToBatch(file.id)
                                                    }
                                                />
                                            </label>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </ModalBody>
            {batchDeleteList !== undefined ? (
                <ModalFooter>
                    <button
                        type="button"
                        className="pane-button large highlight"
                        id="batch-delete-confirm"
                        onClick={() => setBatchDeleteState(DELETE.PROMPT)}
                        disabled={batchDeleteList.length === 0}
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        className="pane-button large"
                        id="batch-delete-cancel"
                        onClick={() => setBatchDeleteList(undefined)}
                    >
                        Cancel
                    </button>
                </ModalFooter>
            ) : null}
            <Modal
                isOpen={modalDelete}
                toggle={() => toggleDelete(undefined)}
                keyboard={false}
                centered
                className="modal-confirm"
            >
                <ModalBody>
                    <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                    <span className="big-modal-text">Are you sure?</span>
                    <span className="small-modal-text">
                        The file, {getBaseName(currentDeletedItem)}, will be removed from this dataset for all projects.
                    </span>
                    <button
                        type="button"
                        className="pane-button large highlight"
                        onClick={() => deleteFiles([currentDeletedItem])}
                    >
                        Yes, Remove this File
                    </button>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="pane-button large" onClick={() => toggleDelete(undefined)}>
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={batchDeleteState !== null} centered className="modal-confirm">
                {batchDeleteModalContent()}
            </Modal>
        </>
    );
};

export default SourceFileTable;
