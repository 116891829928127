import type ElevationRange from '@giro3d/giro3d/core/ElevationRange';

import * as giro3dSlice from 'redux/giro3d';
import * as graphicsSettingsSlice from 'redux/graphicsSettings';
import * as gridSlice from 'redux/grid';
import * as layersSlice from 'redux/layers';
import * as crossSectionsSlice from 'redux/crossSections';
import * as layoutSlice from 'redux/layout';
import * as datasetsSlice from 'redux/datasets';
import * as seismicViewSlice from 'redux/seismicView';
import * as seismicGridSlice from 'redux/seismicGrid';

import { Dispatch, RootState } from 'store';
import ColorMap from 'types/ColorMap';
import {
    ColoringMode,
    DataPointMode,
    FeatureFilter,
    LayerState,
    VectorStyle,
    canShowInMinimap,
    hasAmplitude,
    hasArrows,
    hasAttributes,
    hasBorehole,
    hasColorimetry,
    hasColoringMode,
    hasDataPointMode,
    hasDraping,
    hasMasks,
    hasOpacity,
    hasOverlayColor,
    hasRadius,
    hasSeismicPlane,
    hasVectorStyle,
    isPointCloud,
} from 'types/LayerState';
import {
    AttributeName,
    DatasetGroupId,
    DatasetId,
    Optional,
    SerializableColor,
    SourceFileId,
    ViewId,
} from 'types/common';
import {
    Annotations,
    Axisgrid,
    Contourlines,
    CrossSection,
    Hillshading,
    SerializedLayerState,
    View,
    ViewSettings,
    LayerGroup,
    SerializedSourceFileState,
    Layout,
    Selection,
    SeismicAxisgrid,
    SeismicView,
} from 'types/serialization/View';
import { selectAnnotationById } from 'redux/annotationActions';
import { Model } from 'flexlayout-react';
import { useEventBus } from 'EventBus';
import { GEOJSON_AMPLITUDE_MODE, ORDER_MODE } from './Constants';

export function loadView(view: View, id: ViewId, dispatch: Dispatch) {
    dispatch(giro3dSlice.loadView({ view, id }));
    dispatch(gridSlice.loadView(view));
    dispatch(seismicViewSlice.loadView(view));
    dispatch(seismicGridSlice.loadView(view));
    dispatch(graphicsSettingsSlice.loadView(view));
    dispatch(layersSlice.loadView(view));
    dispatch(crossSectionsSlice.loadView(view));
    if (view.layerOrder) {
        dispatch(datasetsSlice.reorderDatasets(view.layerOrder));
    }
    if (view.annotations) {
        dispatch(selectAnnotationById(view.annotations.selected));
    }
    dispatch(layoutSlice.loadView(view));
    if (view.layout?.modelRoot) useEventBus().dispatch('overwrite-model', { jsonModel: view.layout.modelRoot });
    dispatch(datasetsSlice.loadView(view));
}

function serializeView(state: RootState): ViewSettings {
    const cam = state.giro3d.camera;
    return {
        camera: cam ? cam.position : null,
        target: cam ? cam.target : null,
        focalOffset: cam ? cam.focalOffset : null,
        inspectorVisible: state.giro3d.showInspector,
        zScale: state.giro3d.zScale,
        selectionShown: state.giro3d.selectionShown,
        axisCompass: state.giro3d.axisCompass,
        coordinates: state.giro3d.coordinates,
        zoom: cam ? cam.zoom : null,
    };
}

function serializeContourLines(state: RootState): Contourlines {
    const source = state.graphicsSettings.contourLines;
    return {
        enabled: source.enabled,
        opacity: source.opacity,
        color: source.color,
        primary: source.primaryInterval,
        secondary: source.secondaryInterval,
    };
}

function serializeHillshading(state: RootState): Hillshading {
    const source = state.graphicsSettings.hillshading;
    return {
        enabled: source.enabled,
        zenith: source.zenith,
        azimuth: source.azimuth,
        intensity: source.intensity,
    };
}

function serializeAxisGrid(state: RootState): Axisgrid {
    const source = state.grid;
    return {
        visible: source.visible,
        verticals: source.showSides,
        labels: source.showLabels,
        color: source.color,
        ceiling: source.showCeiling,
        ceilingLevel: source.ceilingLevel,
        floorLevel: source.floorLevel,
        opacity: source.opacity,
        ticks: source.ticks,
    };
}

function serializeOpacityProperties(source: LayerState): Optional<{ opacity: number }> {
    if (hasOpacity(source)) {
        return {
            opacity: source.opacity,
        };
    }

    return undefined;
}

function serializeMinimapProperties(source: LayerState): Optional<{ showInMinimap: boolean }> {
    if (canShowInMinimap(source)) {
        return {
            showInMinimap: source.showInMinimap,
        };
    }

    return undefined;
}

function serializeMaskProperties(source: LayerState): Optional<{ masks: DatasetId[] }> {
    if (hasMasks(source)) {
        return {
            masks: source.masks,
        };
    }

    return undefined;
}

function serializeDrapingProperties(source: LayerState): Optional<{
    isDraped: boolean;
    undrapedZOffset: number;
    enableClippingRange: boolean;
    clippingRange: ElevationRange;
}> {
    if (hasDraping(source)) {
        return {
            isDraped: source.isDraped,
            undrapedZOffset: source.zOffset,
            clippingRange: source.clippingRange,
            enableClippingRange: source.enableClippingRange,
        };
    }

    return undefined;
}

function serializePointCloudProperties(source: LayerState): Optional<{
    pointCloudSize: number;
}> {
    if (isPointCloud(source)) {
        return {
            pointCloudSize: source.pointCloudSize,
        };
    }

    return undefined;
}

function serializeRadiusProperties(source: LayerState): Optional<{
    radius: number;
}> {
    if (hasRadius(source)) {
        return {
            radius: source.radius,
        };
    }

    return undefined;
}

function serializeSeismicProperties(source: LayerState): Optional<{
    seismicFilterTransparency: boolean;
    seismicStaticCorrection: boolean;
    seismicIntensityFilter: number;
    seismicPlaneZOffset: number;
    seismicSpeedModule: number;
    seismicActiveFile: SourceFileId;
    seismicLineOrderMode: ORDER_MODE;
    seismicInlineDirection: number;
    seismicInlineTolerance: number;
    seismicCrosslineDirection: number;
    seismicCrosslineTolerance: number;
    seismicInlineTerm: string;
    seismicCrosslineTerm: string;
}> {
    if (hasSeismicPlane(source)) {
        return {
            seismicStaticCorrection: source.staticCorrection,
            seismicFilterTransparency: source.filterTransparency,
            seismicIntensityFilter: source.intensityFilter,
            seismicPlaneZOffset: source.seismicOffset,
            seismicSpeedModule: source.speedModuleMs,
            seismicActiveFile: source.activeFile,
            seismicLineOrderMode: source.lineOrderMode,
            seismicInlineDirection: source.inlineDirection,
            seismicInlineTolerance: source.inlineTolerance,
            seismicCrosslineDirection: source.crosslineDirection,
            seismicCrosslineTolerance: source.crosslineTolerance,
            seismicInlineTerm: source.inlineTerm,
            seismicCrosslineTerm: source.crosslineTerm,
        };
    }

    return undefined;
}

function serializeArrowProperties(source: LayerState): Optional<{
    arrowScale: number;
    arrowSpacing: number;
    showArrows: boolean;
}> {
    if (hasArrows(source)) {
        return {
            arrowScale: source.arrowScale,
            arrowSpacing: source.arrowSpacing,
            showArrows: source.showArrows,
        };
    }

    return undefined;
}

function serializeBoreholeProperties(source: LayerState): Optional<{
    showBorehole: boolean;
    variableRadii: boolean;
}> {
    if (hasBorehole(source)) {
        return {
            variableRadii: source.variableRadii,
        };
    }

    return undefined;
}

function serializeOverlayColor(source: LayerState): Optional<{
    overlayColor: SerializableColor;
}> {
    if (hasOverlayColor(source)) {
        return {
            overlayColor: source.overlayColor,
        };
    }

    return undefined;
}

function serializeColoringMode(source: LayerState): Optional<{
    currentColoringMode: ColoringMode;
}> {
    if (hasColoringMode(source)) {
        return {
            currentColoringMode: source.currentColoringMode,
        };
    }

    return undefined;
}

function serializeAttributeProperties(source: LayerState): Optional<{
    colorMaps: Record<AttributeName, ColorMap>;
    activeAttribute: string;
    pinLegend: boolean;
}> {
    if (hasAttributes(source)) {
        const result = {};

        for (const [attributeName, colorMap] of Object.entries(source.colorMaps)) {
            result[attributeName] = colorMap;
        }

        return {
            colorMaps: result,
            activeAttribute: source.activeAttribute,
            pinLegend: source.pinLegend,
        };
    }

    return undefined;
}

function serializeAmplitudeProperties(source: LayerState): Optional<{
    amplitudeSettings: Record<AttributeName, { range: number; showAmplitude: boolean; mode: GEOJSON_AMPLITUDE_MODE }>;
}> {
    if (hasAmplitude(source)) {
        const result = {};

        for (const [attributeName, setting] of Object.entries(source.perAttributeAmplitudeSettings)) {
            result[attributeName] = {
                showAmplitude: setting.showAmplitude,
                range: setting.range,
                mode: setting.mode,
            };
        }

        return {
            amplitudeSettings: result,
        };
    }

    return undefined;
}

function serializeVectorStyleProperties(source: LayerState): Optional<{
    vectorStyle: VectorStyle;
    featureFilter: FeatureFilter;
}> {
    if (hasVectorStyle(source)) {
        return {
            vectorStyle: source.vectorStyle,
            featureFilter: source.featureFilter,
        };
    }

    return undefined;
}

function serializeDataPointMode(source: LayerState): Optional<{
    currentDataPointMode: DataPointMode;
}> {
    if (hasDataPointMode(source)) {
        return {
            currentDataPointMode: source.currentDataPointMode,
        };
    }

    return undefined;
}

function serializeColorimetryProperties(source: LayerState): Optional<{
    brightness: number;
    contrast: number;
    saturation: number;
}> {
    if (hasColorimetry(source)) {
        return {
            brightness: source.brightness,
            contrast: source.contrast,
            saturation: source.saturation,
        };
    }

    return undefined;
}

export function serializeLayerState(source: LayerState) {
    return {
        visible: source.visible,
        ...serializeOpacityProperties(source),
        ...serializeMinimapProperties(source),
        ...serializeMaskProperties(source),
        ...serializeDrapingProperties(source),
        ...serializePointCloudProperties(source),
        ...serializeRadiusProperties(source),
        ...serializeSeismicProperties(source),
        ...serializeArrowProperties(source),
        ...serializeBoreholeProperties(source),
        ...serializeOverlayColor(source),
        ...serializeColoringMode(source),
        ...serializeAttributeProperties(source),
        ...serializeAmplitudeProperties(source),
        ...serializeVectorStyleProperties(source),
        ...serializeDataPointMode(source),
        ...serializeColorimetryProperties(source),
    };
}

function serializeLayers(state: RootState): Record<string, SerializedLayerState> {
    const result = {};

    for (const layerState of Object.values(state.layers.layersById)) {
        result[layerState.datasetId] = serializeLayerState(layerState);
    }

    return result;
}

function serializeLayerOrdering(state: RootState): DatasetId[] {
    return layersSlice.orderedIds(state);
}

function serializeCrossSection(state: RootState): CrossSection {
    const slice = state.crossSections;

    return {
        enabled: slice.visible,
        azimuth: slice.azimuthDegrees,
        center: slice.center,
        color: slice.color,
        opacity: slice.opacity,
        invert: slice.invert,
    };
}

function serializeGroups(state: RootState): Record<DatasetGroupId, LayerGroup> {
    return {
        ...layersSlice.getGroups(state),
    };
}

function serializeAnnotations(state: RootState): Annotations {
    return {
        selected: state.annotations.active,
    };
}

function serializeSourceFiles(state: RootState): Record<SourceFileId, SerializedSourceFileState> {
    const input = state.layers.sourceFilesById;
    const result: Record<SourceFileId, SerializedSourceFileState> = {};

    for (const [id, sf] of Object.entries(input)) {
        result[id] = {
            datasetId: sf.datasetId,
            visible: sf.visible,
            id: sf.id,
        };
    }

    return result;
}

function serializeLayout(state: RootState, model: Model): Layout {
    const layout = state.layout;

    return {
        leftOpen: layout.leftOpen,
        rightOpen: layout.rightOpen,
        trayOpen: layout.trayOpen,
        modelRoot: model?.toJson().layout ?? null,
    };
}

function serializeSelections(state: RootState): Selection {
    const slice = state.datasets;

    return {
        seismicDataset: slice.seismicDataset,
        seismicSourceFile: slice.seismicSourceFile,
    };
}

function serializeSeismicView(state: RootState): SeismicView {
    const source = state.seismicView;

    return {
        reversed: source.reversed,
        maintainDirection: source.maintainDirection,
        zScale: source.zScale,
        snap: source.snapPosition,
        depthChange: source.showDepthChange,
    };
}

function serializeSeismicGrid(state: RootState): SeismicAxisgrid {
    const source = state.seismicGrid;

    return {
        visible: source.visible,
        labels: source.showLabels,
        color: source.color,
        opacity: source.opacity,
        ticks: source.ticks,
    };
}

export function serialize(state: RootState, model?: Model): View {
    return {
        view: serializeView(state),
        contourlines: serializeContourLines(state),
        mapresolution: state.graphicsSettings.mapSegments,
        hillshading: serializeHillshading(state),
        pointcloud: {
            edl: state.graphicsSettings.eyeDomeLighting,
            inpainting: state.graphicsSettings.inpainting,
        },
        layerOrder: serializeLayerOrdering(state),
        axisgrid: serializeAxisGrid(state),
        layers: serializeLayers(state),
        sourceFiles: serializeSourceFiles(state),
        crossSections: serializeCrossSection(state),
        groups: serializeGroups(state),
        annotations: serializeAnnotations(state),
        layout: serializeLayout(state, model),
        selections: serializeSelections(state),
        seismicView: serializeSeismicView(state),
        seismicGrid: serializeSeismicGrid(state),
    };
}
