import { LayerState } from 'types/LayerState';
import { DatasetId } from 'types/common';
import store, { RootState } from 'store';
import { SourceFileState } from 'types/SourceFileState';
import StateObserver from './StateObserver';

/**
 * Observes redux state changes for a specific {@link LayerState}.
 */
export default class LayerStateObserver<TLayerState = LayerState, TSourceFileState = SourceFileState> {
    private readonly _observer: StateObserver<RootState> = new StateObserver(store.getState, store.subscribe);
    private readonly _datasetId: string;
    private readonly _fileId: string;

    constructor(datasetId: DatasetId, fileId: string) {
        if (!datasetId) {
            throw new Error('datasetId is not defined');
        }
        this._datasetId = datasetId;
        this._fileId = fileId;
    }

    /**
     * Triggers all listeners once with the current state, regardless
     * whether the actual state has changed. Useful to initialize the Layer
     * with the initial values.
     */
    initialize() {
        this._observer.triggerAllListeners();
    }

    /**
     * Returns the {@link LayerState}.
     */
    getLayer(): TLayerState {
        return this._observer.select((s) => s.layers.layersById[this._datasetId] as TLayerState);
    }

    /**
     * Returns the {@link SourceFileState}.
     */
    getSourceFile(): TSourceFileState {
        return this._observer.select((s) => s.layers.sourceFilesById[this._fileId] as TSourceFileState);
    }

    select<T>(selector: (state: RootState) => T): T {
        return this._observer.select((s) => {
            const layerState = s.layers.layersById[this._datasetId] as TLayerState;
            if (layerState) {
                return selector(s);
            }
            return undefined;
        });
    }

    /**
     * Subscribe to a particular layer state change through the specified selector.
     * @param selector The selector function.
     * @param callback The listener callback. Note: triggered only when the selector value does change.
     * @param equalityFn Optional equality function to compare states.
     */
    subscribe<T>(
        selector: (state: RootState) => T,
        callback: (v: T) => void,
        equalityFn?: (left: T, right: T) => boolean
    ) {
        this._observer.subscribe(
            (s) => {
                const layerState = s.layers.layersById[this._datasetId] as TLayerState;
                // The layerState may not exist in the previous state used for
                // comparison purposes.
                if (layerState) {
                    return selector(s);
                }
                return undefined;
            },
            callback,
            equalityFn
        );
    }

    dispose() {
        this._observer.dispose();
    }
}
