import { useEffect, useState } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { Geometry as OLGeometry } from 'ol/geom';
import * as layersSlice from 'redux/layers';
import { getService } from 'ServiceContainer';
import { useAppSelector } from 'store';
import TabHeader from 'components/flexLayout/TabHeader';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { Feature } from 'ol';
import { Button, PopoverBody, Spinner } from 'reactstrap';
import { useEventBus } from 'EventBus';
import { Box2, Vector2 } from 'three';
import { DatasetId, SourceFileId } from 'types/common';
import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';
import giro3dService from 'services/Giro3dService';
import Toggle from 'components/dropdown/Toggle';
import { buffer } from 'ol/extent';
import { LAYER_STATES, SOURCE_FILE_STATES } from 'services/Constants';

const goToFeature = (datasetId: DatasetId, sourceFileId: SourceFileId, feature: Feature<OLGeometry>) => {
    const eventBus = useEventBus();
    const extent = feature.getGeometry().clone().getExtent();
    if (feature.getGeometry().getType() === 'Point') buffer(extent, 5, extent);
    eventBus.dispatch('go-to-extent', {
        extent: new Box2(new Vector2(extent[0], extent[1]), new Vector2(extent[2], extent[3])),
    });
    giro3dService.selectFeature(datasetId, sourceFileId, feature);
};

function generateColumns(
    datasetId,
    features: Record<SourceFileId, Feature<OLGeometry>[]>
): ColumnDef<Feature<OLGeometry>>[] {
    // Flatten all features from all source files
    const allFeatures = Object.values(features).flat();
    if (allFeatures.length === 0) return [];

    // Collect all unique headers from all features
    const colHeaders: string[] = Array.from(
        new Set(allFeatures.flatMap((feature) => Object.keys(feature.getProperties()).filter((h) => h !== 'geometry')))
    );

    return [
        {
            header: () => 'ol uid',
            id: 'ol uid',
            // @ts-expect-error ol_uid does exist
            accessorFn: (props) => props.ol_uid,
        },
        {
            header: () => 'Actions',
            id: 'actions',
            cell: ({ row }) => {
                const sourceFileId = Object.keys(features).find((key) =>
                    // @ts-expect-error ol_uid does exist
                    features[key].some((feature) => feature.ol_uid === row.original.ol_uid)
                );
                return (
                    <Button
                        color="link"
                        id={`attributetable-gotobtn-${row.id}`}
                        title="Go to feature"
                        onClick={() => goToFeature(datasetId, sourceFileId, row.original)}
                    >
                        <i className="fal fa-location-arrow fa-fw" />
                    </Button>
                );
            },
        },
        ...colHeaders.map((header) => ({
            header: () => header,
            id: header,
            accessorFn: (props) => props.getProperties()[header],
        })),
    ];
}

type Props = {
    datasetId: DatasetId;
};

const AttributeTable = (props: Props) => {
    const featureManager = getService('FeatureManager');
    const dataset = useAppSelector(datasetsSlice.get(props.datasetId));
    const currentLayerState = useAppSelector(layersSlice.get(dataset.id));
    const sourceFiles = useAppSelector(datasetsSlice.getSourceFiles(dataset.id));
    const requiredSourceFiles = sourceFiles?.reduce((arr, s) => {
        if ([SOURCE_FILE_STATES.ACTIVE, SOURCE_FILE_STATES.LOADING, SOURCE_FILE_STATES.LOADED].includes(s.state))
            arr.push(s.id);
        return arr;
    }, []);
    const allFilesReady = useAppSelector(layersSlice.getVectorFeaturesReadyFiles(requiredSourceFiles || []));

    const feature = useAppSelector(giro3dSlice.getSelection)?.feature;

    const [features, setFeatures] = useState<Record<string, Feature<OLGeometry>[]>>();
    const [columns, setColumns] = useState<ColumnDef<Feature<OLGeometry>>[]>();

    useEffect(() => {
        const f = featureManager.getFeatures(dataset?.id);
        setFeatures(f);
        setColumns(generateColumns(dataset?.id, f));
    }, [allFilesReady]);

    const [pagination, setPagination] = useState({
        pageIndex: 0, // initial page index
        pageSize: 50, // default page size
    });

    const table = useReactTable({
        columns,
        data: features ? Object.values(features).flat() : [],
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        autoResetPageIndex: false,
    });

    useEffect(() => {
        if (dataset && currentLayerState) {
            giro3dService.loadLayer(currentLayerState);
            giro3dService.getLayersForDataset(dataset.id).forEach((layer) => layer.init());
        }
    }, [dataset, currentLayerState]);

    if (!dataset) return <span>No Dataset</span>;
    if (dataset.state !== LAYER_STATES.LOADED || !allFilesReady) return <Spinner />;

    return (
        <>
            <TabHeader
                left={
                    <HeaderButton
                        popover={{
                            name: 'Columns',
                            icon: 'fas fa-columns-3',
                            content: (
                                <PopoverBody>
                                    <ul>
                                        {table.getAllLeafColumns().map((column) => (
                                            <Toggle
                                                key={column.id}
                                                title={column.id}
                                                checked={column.getIsVisible()}
                                                onChange={column.toggleVisibility}
                                            />
                                        ))}
                                    </ul>
                                </PopoverBody>
                            ),
                        }}
                        key="columns"
                    />
                }
                center={undefined}
                right={
                    <>
                        <HeaderButton
                            button={{
                                name: 'First Page',
                                icon: 'fas fa-arrow-left-to-line',
                                onClick: () => table.firstPage(),
                                disabled: !table.getCanPreviousPage(),
                            }}
                            key="first-page"
                        />
                        <HeaderButton
                            button={{
                                name: 'Previous Page',
                                icon: 'fas fa-arrow-left',
                                onClick: () => table.previousPage(),
                                disabled: !table.getCanPreviousPage(),
                            }}
                            key="previous-page"
                        />
                        {table.getState().pagination.pageIndex + 1}/{table.getPageCount()}
                        <HeaderButton
                            button={{
                                name: 'Next Page',
                                icon: 'fas fa-arrow-right',
                                onClick: () => table.nextPage(),
                                disabled: !table.getCanNextPage(),
                            }}
                            key="next-page"
                        />
                        <HeaderButton
                            button={{
                                name: 'Last Page',
                                icon: 'fas fa-arrow-right-to-line',
                                onClick: () => table.lastPage(),
                                disabled: !table.getCanNextPage(),
                            }}
                            key="last-page"
                        />
                    </>
                }
            />

            <div className="tabContent freeScroll">
                <table>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr
                                    key={row.id}
                                    // @ts-expect-error ol_uid exists
                                    className={row.original.ol_uid === feature?.ol_uid ? 'highlight' : ''}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AttributeTable;
