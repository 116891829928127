import { type Box3, Clock, type Plane, EventDispatcher, Object3D, MathUtils, Vector3, Color } from 'three';
import type Instance from '@giro3d/giro3d/core/Instance';
import { ZSCALE_BEHAVIOR } from 'services/Constants';
import { HasFootprint, LayerState, canShowInMinimap, hasFootprint } from 'types/LayerState';
import { DatasetId, GeometryWithCRS, SourceFileId, toPlane } from 'types/common';
import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import * as crossSectionsSlice from 'redux/crossSections';
import { useEventBus } from 'EventBus';
import { Dispatch } from 'store';
import LayerManager from 'giro3d_extensions/LayerManager';
import { getMinMax } from 'redux/buildLayerState';
import helpers from '@turf/helpers';
import pointOnFeature from '@turf/point-on-feature';
import * as geojsonUtils from 'geojsonUtils';
import { sineWave } from 'components/utils';
import { SourceFileState } from 'types/SourceFileState';
import LayerStateObserver from './LayerStateObserver';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayerEventMap {}

export interface Settings {
    datasetVisibility: boolean;
    sourceFileVisibility: boolean;
    sourceFileLabelVisibility: boolean;
    minimapVisibility: boolean;
    zscaleEffect: string;
    zScale: number;
}

export enum HostView {
    MainView,
    MinimapView,
    SeismicView,
}

export interface ConstructorParams {
    instance: Instance;
    datasetId: DatasetId;
    sourceFileId?: SourceFileId;
    layerManager: LayerManager;
    dispatch: Dispatch;
    getFootprint: () => GeometryWithCRS;
    hostView: HostView;
}

const eventBus = useEventBus();

/**
 * Base class for all layers in SCOPE.
 * A layer is a **graphical representation** of a dataset or any other business object.
 *
 * The `Layer` reacts to changes in the source dataset (through the {@link LayerState `LayerState`} Redux object).
 *
 * The lifecycle of the layer follows those steps:
 * - **created**: the layer is ready to listen to state changes
 * - **initialized**: the 3D objects are loaded
 * - **disposed**: the 3D objects are removed and the layer stops listening to state changes.
 * When the Layer is disposed, it may not be used again.
 *
 * @param TLayerState The type of the {@link LayerState `LayerState`} that this Layer listens to.
 */
abstract class Layer<
    TSettings extends Settings = Settings,
    TEventMap extends LayerEventMap = LayerEventMap,
    TLayerState extends LayerState = LayerState,
> extends EventDispatcher<TEventMap> {
    /** The ID of the dataset that this layer represents */
    readonly datasetId: DatasetId;
    readonly sourceFileId: SourceFileId;
    initialized = false;
    protected readonly settings: TSettings;
    protected readonly _giro3dInstance: Instance;
    protected readonly _layerObserver: LayerStateObserver<TLayerState>;
    protected _cachedFootprint: GeometryWithCRS;
    protected readonly _getFootprint: () => GeometryWithCRS;
    protected readonly _dispatch: Dispatch;
    protected readonly _layerManager: LayerManager;
    private _initOnce: Promise<void>;
    private _disposed: boolean;
    protected readonly _layerState: TLayerState;
    protected readonly _fileState: SourceFileState;
    protected _hostView: HostView;

    /**
     * Creates an instance of Layer.
     * @param params The parameters
     * @param params.visible Visibilility for this layer
     * @param params.getFootprint The footprint of the layer (as a GeoJSON object).
     */
    constructor(params: ConstructorParams) {
        super();
        this._giro3dInstance = params.instance;
        this._getFootprint = params.getFootprint;
        this._dispatch = params.dispatch;
        this._layerManager = params.layerManager;
        this.datasetId = params.datasetId;
        this.sourceFileId = params.sourceFileId;
        this._layerObserver = new LayerStateObserver(this.datasetId, this.sourceFileId);
        this._layerState = this._layerObserver.getLayer();
        this._fileState = this._layerObserver.getSourceFile();
        this.setHostView(params.hostView);

        this.settings = {
            datasetVisibility: this._layerObserver.select(layersSlice.isVisibleSelf(this._layerState)),
            minimapVisibility: true,
            sourceFileVisibility: this._layerObserver.select(layersSlice.getSourceFileVisibility(this._fileState)),
            zscaleEffect: ZSCALE_BEHAVIOR.BY_PARENT,
            zScale: 1,
        } as TSettings;

        this.subscribeToStateChanges(this._layerObserver);
    }

    get layerState() {
        return this._layerState;
    }

    protected throwIfDisposed() {
        if (this._disposed) {
            throw new Error('Layer is disposed');
        }
    }

    protected subscribeToStateChanges(observer: LayerStateObserver<TLayerState>) {
        observer.subscribe(layersSlice.isVisibleSelf(this._layerState), (v) => this.setThisVisibility(v));
        observer.subscribe(crossSectionsSlice.getPlane, (v) => this.setClippingPlane(toPlane(v)));
        observer.subscribe(layersSlice.getSourceFileVisibility(this._fileState), (v) =>
            this.setSourceFileVisibility(v)
        );

        if (hasFootprint(this._layerState)) {
            observer.subscribe(layersSlice.isVisibleSelf(this._layerState), (v) => this.showFootprint(v));
            observer.subscribe(layersSlice.getFootprintLabelVisibility(this._layerState), (v) =>
                this.showFootprintLabels(v)
            );
        }
    }

    private showFootprintLabels(show: boolean) {
        this.settings.sourceFileLabelVisibility = show;
        this._layerManager.updateFootprint({
            id: this.sourceFileId,
            displayFill: !this.getSourceFileVisibility(),
            displayLabels: show,
        });
    }

    private async showFootprint(show: boolean) {
        if (show) {
            const footprint = await this.getRenderableFootprint();

            const layer = this._layerState as unknown as LayerState & HasFootprint;

            const color = this._layerObserver.select(layersSlice.getFootprintColor(layer));

            const label = this._layerObserver.select(
                datasetsSlice.getSourceFilename(this.datasetId, this.sourceFileId)
            );

            // FIXME drape label position correctly. If the dataset is not bathymetry, the label
            // will float somewhere.
            const [x, y] = pointOnFeature(footprint as helpers.Geometry).geometry.coordinates;
            const minmax =
                getMinMax(this._layerObserver.select(datasetsSlice.get(this.datasetId))) ??
                this._layerObserver.select(datasetsSlice.totalElevationRange);
            const z = minmax ? MathUtils.lerp(minmax.min, minmax.max, 0.5) : 0;
            const labelPosition = new Vector3(x, y, z);

            await this._layerManager.addFootprint({
                id: this.sourceFileId,
                sourceFileId: this.sourceFileId,
                datasetId: this.datasetId,
                footprint,
                color: new Color(color),
                label,
                labelPosition,
                displayFill: !this.getSourceFileVisibility(),
                displayLabels: this.settings.sourceFileLabelVisibility,
            });
        } else {
            this._layerManager.removeFootprint(this.sourceFileId);
        }
    }

    /**
     * Initializes the object with observed values.
     * This must be called at the end of the constructor, when the layer is ready.
     */
    protected assignInitialValues() {
        this._layerObserver.initialize();
    }

    getRenderableFootprint() {
        if (this._cachedFootprint) {
            return this._cachedFootprint;
        }
        const footprint = this.getFootprint();

        // FIXME tracklines footprints do not have a CRS
        const crsIn = footprint.crs?.properties?.name ?? this._giro3dInstance.referenceCrs;
        const crsOut = this._giro3dInstance.referenceCrs;
        this._cachedFootprint = geojsonUtils.transform(footprint, crsIn, crsOut);

        return this._cachedFootprint;
    }

    getFootprint() {
        if (!this._getFootprint) {
            throw this._notImplementedError('getFootprint()');
        }
        return this._getFootprint();
    }

    /**
     * Initializes the underlying 3D objects.
     */
    init(): Promise<void> {
        this.throwIfDisposed();
        // Ensures that initialization is idempotent.
        if (!this._initOnce) {
            this._initOnce = this.initOnce()
                .then(() => {
                    this.initialized = true;
                    this.assignInitialValues();
                    eventBus.dispatch('layer-initialized', { layer: this.datasetId });
                })
                .catch(console.error);
        }
        return this._initOnce;
    }

    /**
     * The initialization function for this layer. Initialization in this
     * context means loading the underlying 3D assets.
     * Note: for performance reasons: the Layer is not initialized until it becomes visible.
     */
    protected abstract initOnce(): Promise<void>;

    protected notifyLayerChange() {
        if (!this.initialized) {
            return;
        }
        this._giro3dInstance.notifyChange(this.get3dElement());
    }

    _notImplementedError(methodName: string) {
        return {
            name: 'NotImplementedError',
            message: `Method ${methodName} is not implemented for ${this.constructor.name}.`,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    getLoading() {
        // Override this if the layer handles asynchronous loading.
        return false;
    }

    abstract get3dElement();

    abstract setOpacity(opacity: number): void;

    abstract getOpacity(): number;

    setZScale(scale: number) {
        this.settings.zScale = scale;
        if (this.initialized) {
            // Note: this function is reimplemented for PointcloudLayer
            const obj = this.get3dElement() as Object3D;
            if (this.settings.zscaleEffect === ZSCALE_BEHAVIOR.COUNTERACT) {
                // Correct objects that should not scale
                obj.scale.set(1, 1, 1 / scale);
                obj.updateMatrix();
                obj.updateMatrixWorld(true);
                this.notifyLayerChange();
            } else if (this.settings.zscaleEffect === ZSCALE_BEHAVIOR.SCALE) {
                // Apply scale to objects directly added to the instance
                obj.scale.set(1, 1, scale);
                obj.updateMatrix();
                obj.updateMatrixWorld(true);
                this.notifyLayerChange();
            }
        }
    }

    /**
     * Gets the overall visibility of this layer.
     * Layer is visible iff it's own visibility is `true` and his parent is `true`
     * @returns Visibility of this layer
     */
    getVisibility(): boolean {
        this.throwIfDisposed();
        return this.getDatasetVisibility() && this.getMinimapVisibility() && this.getSourceFileVisibility();
    }

    /**
     * Applies visibility to this layer
     * @param visible New visibility
     */
    protected abstract doSetVisibility(visible: boolean): Promise<void>;

    /**
     * Gets the dataset-specific visibility.
     * Use `getVisibility` for rendering.
     * @returns Layer-specific visibility
     */
    protected getDatasetVisibility() {
        return this.settings.datasetVisibility ?? false;
    }

    /**
     * Gets the minimap-specific visibility.
     * Use `getVisibility` for rendering.
     * @returns minimap-specific visibility
     */
    getMinimapVisibility() {
        return this.settings.minimapVisibility ?? false;
    }

    /**
     * Gets the sourceFile-specific visibility.
     * Use `getVisibility` for rendering.
     * @returns sourceFile-specific visibility
     */
    getSourceFileVisibility() {
        return this.settings.sourceFileVisibility ?? false;
    }

    /**
     * Sets the layer-specific visibility and applies the overall visibility.
     * @param visible New visibility
     */
    async setThisVisibility(visible: boolean) {
        this.settings.datasetVisibility = visible;
        if (this.shouldInitialize()) {
            await this.init();
        }
        await this.doSetVisibility(this.getVisibility());
    }

    setHostView(host: HostView) {
        this._hostView = host;
        if (host === HostView.MinimapView && canShowInMinimap(this._layerState)) {
            this._layerObserver.subscribe(layersSlice.isShownInMinimap(this._layerState), (v) =>
                this.setMinimapVisibility(v)
            );
        }
    }

    shouldInitialize() {
        return !this.initialized && this.getVisibility();
    }

    /**
     * Sets the layer-specific visibility and applies the overall visibility.
     * Even if providing `true`, layer can be hidden if his parent is hidden.
     * @param visible New visibility
     */
    async setMinimapVisibility(visible: boolean) {
        this.settings.minimapVisibility = visible;
        if (this.shouldInitialize()) {
            await this.init();
        }
        await this.doSetVisibility(this.getVisibility());
    }

    /**
     * Sets the source file-specific visibility and applies the overall visibility.
     * Even if providing `true`, layer can be hidden if his parent is hidden.
     * @param visible New visibility
     */
    async setSourceFileVisibility(visible: boolean) {
        this.settings.sourceFileVisibility = visible;
        if (this.shouldInitialize()) {
            await this.init();
        }
        if (visible === undefined) {
            this._layerManager.removeFootprint(this.sourceFileId);
        } else {
            this._layerManager.updateFootprint({
                id: this.sourceFileId,
                displayFill: !this.getSourceFileVisibility(),
                displayLabels: this.settings.sourceFileLabelVisibility,
            });
        }
        await this.doSetVisibility(this.getVisibility());
    }

    abstract getBoundingBox(scaleFactor: number): Box3;

    // eslint-disable-next-line class-methods-use-this, no-unused-vars, @typescript-eslint/no-unused-vars
    protected setClippingPlane(plane: Plane) {
        // Default implementation does nothing
    }

    // eslint-disable-next-line class-methods-use-this
    protected sineWave(setValue: (value: number) => void) {
        const clock = new Clock();
        const frequency = 2;
        const duration = 0.7;
        let t = 0;

        const update = () => {
            t += clock.getDelta() / duration;

            const value = sineWave(t, frequency);

            setValue(value);

            if (t < 1) {
                requestAnimationFrame(update);
            } else {
                setValue(0);
            }
        };

        update();
    }

    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    protected setBrightness(brightness: number): void {
        // Do nothing
    }

    flashHighlight() {
        this.sineWave((brightness: number) => {
            this.setBrightness(brightness);
            this.notifyLayerChange();
        });
    }

    /**
     * Disposes this layer and frees unmanaged resources.
     */
    dispose() {
        if (this._disposed) {
            return;
        }
        this._disposed = true;
        this._layerObserver.dispose();
        this.onDispose();
    }

    protected onDispose(): void {
        if (hasFootprint(this._layerState)) {
            this._layerManager.removeFootprint(this.sourceFileId);
        }
    }
}

export default Layer;
