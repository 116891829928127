import { Color, Sprite, SpriteMaterial, TextureLoader, Vector3 } from 'three';
import { DEFAULT_BOREHOLE_SETTINGS } from 'services/Constants';

import * as layersSlice from 'redux/layers';
import {
    HasAttributes,
    HasBorehole,
    HasColoringMode,
    HasOpacity,
    HasOverlayColor,
    HasRadius,
    LayerState,
} from 'types/LayerState';
import LineLayer, { type ConstructorParams, Settings as BaseSettings } from './LineLayer';
import LayerStateObserver from '../LayerStateObserver';

export interface Settings extends BaseSettings {
    showBorehole: boolean;
}

type L = LayerState & HasOverlayColor & HasOpacity & HasBorehole & HasAttributes & HasRadius & HasColoringMode;

class BoreholeLayer extends LineLayer<Settings, L> {
    readonly isBoreholeLayer = true;

    private _marker: Sprite;

    constructor(params: ConstructorParams) {
        super(params);

        this.type = 'BoreholeLayer';

        this.settings.overlayColor = new Color(DEFAULT_BOREHOLE_SETTINGS.OVERLAY_COLOR);
        this.settings.radius = DEFAULT_BOREHOLE_SETTINGS.RADIUS;
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<L>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getSourceFileVisibility(this._fileState), (v) => this.setBoreholeVisible(v));
        observer.subscribe(layersSlice.getHasVariableRadii(this._layerState), (v) => this.setVariableRadii(v));
    }

    setSourceFileVisibility(visible: boolean): Promise<void> {
        this.setBoreholeVisible(visible);
        return Promise.resolve();
    }

    hover(hover: boolean): void {
        super.hover(hover);

        if (this._marker) {
            this.showMarkerInFront(hover);
            this._marker.material.color = new Color(this.settings.overlayColor).offsetHSL(0, 0, hover ? 0.9 : 0);
        }
    }

    protected override setBrightness(brightness: number) {
        super.setBrightness(brightness);

        // FIXME: add a way to make sprite marker flashing (and visible at a distance)
        if (this._marker) {
            this.showMarkerInFront(brightness > 0);
            this._marker.material.color = new Color(this.settings.overlayColor).offsetHSL(0, 0, brightness);
        }
    }

    // When markers overlap with each other, this makes the current
    // one pop in front of the other and be much more visible.
    private showMarkerInFront(show: boolean) {
        const material = this._marker.material;
        material.depthTest = !show;
        material.transparent = show ? true : material.opacity < 1;
        this._marker.renderOrder = show ? 9999 : 0;
    }

    private showBorehole(show: boolean) {
        this._lineEntity.visible = show;
        this.notifyLayerChange();
    }

    private setVariableRadii(enable: boolean) {
        this._lineEntity?.setVariableRadii(enable);
    }

    private async loadEntity() {
        if (this._lineEntity || this._isLoadingEntity) {
            return;
        }

        this._isLoadingEntity = true;
        await this._geometrySource.initialize();
        this.initEntity();
    }

    private async setBoreholeVisible(visible: boolean) {
        this.settings.showBorehole = visible;

        if (!this.getVisibility()) {
            return;
        }

        if (visible && !this._lineEntity) {
            await this.loadEntity();
        }
        if (this._lineEntity) {
            this.showBorehole(visible);
        }
    }

    getBoreholeVisible() {
        return this.settings.showBorehole;
    }

    override applyOverlay(): void {
        super.applyOverlay();
        if (this._marker) {
            this._marker.material.color = this.settings.overlayColor;
        }
    }

    override async initOnce() {
        // NOTE: Should await initOnce, but it seems to have a quite negative effect on init performance.
        super.initOnce();

        await this.createBoreholeEntranceMarker();
    }

    private async createBoreholeEntranceMarker() {
        const map = new TextureLoader().load(`${window.location.origin.toString()}/textures/boreholeMarker.png`);
        const material = new SpriteMaterial({
            map,
            color: this.settings.overlayColor,
            alphaTest: 0.5,
            sizeAttenuation: true,
        });

        const marker = new Sprite(material);
        const lines = await this._geometrySource.getGeometries();
        const origin = lines[0].origin;
        // Elevate marker so it is more likely to be visible above bathymetry
        const markerPosition = origin.clone().add(new Vector3(0, 0, this.settings.radius));
        marker.position.copy(markerPosition);
        // Scale marker to the size of the borehole diameter.
        marker.scale.set(2 * this.settings.radius, 2 * this.settings.radius, 2 * this.settings.radius);
        marker.renderOrder = 1;
        this.object3d.add(marker);
        this.object3d.updateMatrixWorld(true);

        this.onClickableObjectCreated(marker);

        marker.name = 'Marker';

        this._marker = marker;

        this.notifyLayerChange();
    }
}

export default BoreholeLayer;
