import { useAppDispatch, useAppSelector } from 'store';
import { DatasetId, SourceFileId } from 'types/common';
import { getDatasetColorHexString } from 'services/DatasetColors';
import { Vector2 } from 'three';
import TabHeader from 'components/flexLayout/TabHeader';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { updateElevationProfile } from 'redux/actions';
import { isElevationType, LAYER_DATA_TYPES } from 'services/Constants';
import Button from 'components/dropdown/Button';
import Slider from 'components/dropdown/Slider';
import Toggle from 'components/dropdown/Toggle';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import giro3dService from 'services/Giro3dService';
import * as datasetsSlice from '../../../redux/datasets';
import * as layersSlice from '../../../redux/layers';

const generateChartData = (points: Vector2[], elevations: Record<string, Record<string, number[]>>): number[][] => {
    const sampleCount = points.length;

    const xStep = points[1].x - points[0].x;
    const yStep = points[1].y - points[0].y;
    const dStep = Math.sqrt(xStep ** 2 + yStep ** 2);

    const data = [];
    const ids: Record<DatasetId, SourceFileId[]> = {};
    Object.entries(elevations).forEach(([datasetId, datasetSet]) => {
        ids[datasetId] = Object.keys(datasetSet);
    });

    for (let i = 0; i < sampleCount; i++) {
        data[i] = [dStep * i, points[i].x, points[i].y];

        Object.entries(ids).forEach(([datasetId, datasetSet]) =>
            datasetSet.forEach((sourceFileId) => data[i].push(elevations[datasetId][sourceFileId][i]))
        );
    }

    return data;
};

type Props = {
    points: Vector2[];
    elevations: Record<DatasetId, Record<SourceFileId, number[]>>;
    tabId: string;
};

const ElevationProfile = (props: Props) => {
    const dispatch = useAppDispatch();
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const layers = useAppSelector(layersSlice.all);

    const validDatasets = datasets.filter(
        (dataset) =>
            isElevationType(dataset.type) &&
            dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
            layers.find((layer) => layer.datasetId === dataset.id).visible
    );

    const { points, elevations, tabId } = props;

    if (!points) return null;

    const horizontalLength = Math.sqrt(
        (points[0].x - points[points.length - 1].x) ** 2 + (points[0].y - points[points.length - 1].y) ** 2
    );
    const [newSampleCount, setNewSampleCount] = useState(points.length);
    const [viewedDatasetIds, setViewedDatasetIds] = useState(Object.keys(elevations));

    const [data, setData] = useState<number[][]>();
    const [options, setOptions] = useState<EChartsOption>();

    const sourceFileToDatasetIdLookup: Record<SourceFileId, DatasetId> = {};
    Object.entries(elevations).forEach(([datasetId, sourceFiles]) => {
        Object.keys(sourceFiles).forEach((sourceFileId) => {
            sourceFileToDatasetIdLookup[sourceFileId] = datasetId;
        });
    });

    const ids: SourceFileId[] = Object.values(elevations)
        .map((datasetSet) => Object.keys(datasetSet))
        .flat();

    useEffect(() => {
        const d = generateChartData(points, elevations);

        setData(d);
        setOptions(
            d
                ? {
                      animation: false,
                      grid: {
                          containLabel: true,
                          top: 10,
                          bottom: 35,
                          left: 10,
                          right: 10,
                      },
                      tooltip: {
                          trigger: 'axis',
                          transitionDuration: 0,
                          // NOTE: For some reason this pair cannot be left undefined by echarts
                          borderColor: '#30383b', // --foreground
                          padding: 8,

                          // Set by css class
                          className: 'elevation-profile-tooltip',
                          backgroundColor: undefined,
                          borderWidth: undefined,
                          borderRadius: undefined,
                          textStyle: { color: undefined },
                          formatter: (params) =>
                              [
                                  tooltipLine('Distance', '#febf80', [params[0].axisValueLabel]),
                                  tooltipLine('X', '#cc3333', [params[0].data[0].toFixed(0)]),
                                  tooltipLine('Y', '#33cc33', [params[0].data[2].toFixed(0)]),
                              ]
                                  .concat(...datasetValues(params))
                                  .join(''),
                      },
                      dataZoom: [
                          {
                              type: 'slider', // Slider zooming
                              xAxisIndex: 0,
                              start: 0,
                              end: 100,
                              height: 15,
                              filterMode: 'weakFilter',
                              minSpan: 1,
                              bottom: 15,
                          },
                          {
                              type: 'inside', // Mouse wheel zooming
                              xAxisIndex: 0,
                              filterMode: 'weakFilter',
                              minSpan: 1,
                          },
                      ],
                      dataset: {
                          source: d,
                      },
                      xAxis: {
                          type: 'value',
                          name: 'Distance',
                          max(value) {
                              return Math.ceil(value.max);
                          },
                      },
                      yAxis: {
                          type: 'value', // Single y-axis for all datasets
                          name: 'Height',
                          max(value) {
                              const digits = Math.round(Math.log10(value.max - value.min));
                              return Math.ceil(value.max / 10 ** digits) * 10 ** digits;
                          },
                          min(value) {
                              const digits = Math.round(Math.log10(value.max - value.min));
                              return Math.floor(value.min / 10 ** digits) * 10 ** digits;
                          },
                      },
                      series: ids.map((id, index) => ({
                          type: 'line',
                          smooth: true,
                          name: id,
                          encode: { x: 0, y: index + 3 },
                          color: `#${getDatasetColorHexString(sourceFileToDatasetIdLookup[id])}`,
                          show: viewedDatasetIds.includes(sourceFileToDatasetIdLookup[id]),
                          seriesLayoutBy: 'column',
                          showSymbol: false,
                      })),
                  }
                : undefined
        );
    }, [elevations]);

    const chartRef = useRef(null);
    const containerRef = useRef(null);

    // Resize handler for forcing charts update
    const handleResize = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance();
        if (echartsInstance) echartsInstance.resize();
    };
    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(containerRef.current);
            return resizeObserver.disconnect;
        }
        return undefined;
    }, []);

    function tooltipLine(name: string, color: string, values: string[]): string {
        return `<table class="elevation-profile-tooltip-line">
                <tr>
                    <td>
                        <span style="background-color:${color};"></span>
                        ${name}: 
                    </td>
                    <td>${values[0]}</td>
                </tr>
                ${values
                    .slice(1)
                    .map(
                        (value, index) =>
                            `<tr key='${index}'>
                        <td></td>
                        <td>${value}</td>
                    </tr>`
                    )
                    .join()}
            </table>`;
    }

    function datasetValues(params) {
        const groups: Record<DatasetId, string[]> = {};
        const lines: string[] = [];

        params.forEach((param) => {
            const v = param.data[3 + param.seriesIndex];
            if (v === null) return;

            const datasetId = sourceFileToDatasetIdLookup[param.seriesName];
            if (!groups[datasetId]) groups[datasetId] = [v];
            else groups[datasetId].push(v);
        });

        Object.entries(groups).forEach(([datasetId, group]) => {
            lines.push(
                tooltipLine(
                    datasets.find((d) => d.id === datasetId).name,
                    `#${getDatasetColorHexString(datasetId)}`,
                    group
                )
            );
        });

        return lines;
    }

    const showMarker = (params) => {
        const heights = (data[params.dataIndex] as number[]).slice(3);
        const fileIndex = heights.indexOf(Math.max(...heights.filter((h) => h !== null)));
        if (fileIndex !== -1) {
            giro3dService.updateCoordinates(
                {
                    point: {
                        x: data[params.dataIndex][1],
                        y: data[params.dataIndex][2],
                        z: heights[fileIndex],
                    },
                    picked: true,
                    color: `#${getDatasetColorHexString(sourceFileToDatasetIdLookup[ids[fileIndex]])}`,
                },
                true
            );
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance() as EChartsInstance;
            chartInstance.on('showTip', showMarker);
        }
        return () => {
            if (chartRef.current) {
                const chartInstance: EChartsInstance = chartRef.current.getEchartsInstance();
                chartInstance.off('showTip', showMarker);
            }
        };
    }, [data]);

    const [legend, setLegend] = useState(false);

    const Legend = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance() as EChartsInstance;

        const highlight = (datasetId: DatasetId) => {
            Object.keys(elevations[datasetId]).forEach((fileId) =>
                echartsInstance.dispatchAction({ type: 'highlight', seriesName: fileId })
            );
        };

        const unhighlight = (datasetId: DatasetId) => {
            Object.keys(elevations[datasetId]).forEach((fileId) =>
                echartsInstance.dispatchAction({ type: 'downplay', seriesName: fileId })
            );
        };

        return (
            <ul className="legends">
                {viewedDatasetIds.map((datasetId) => {
                    const dataset = datasets.find((d) => d.id === datasetId);
                    return (
                        <li
                            key={datasetId}
                            onMouseEnter={() => highlight(datasetId)}
                            onFocus={() => highlight(datasetId)}
                            onMouseLeave={() => unhighlight(datasetId)}
                            onBlur={() => unhighlight(datasetId)}
                        >
                            <i className="fas fa-circle" style={{ color: `#${getDatasetColorHexString(datasetId)}` }} />
                            {dataset.name}
                        </li>
                    );
                })}
            </ul>
        );
    };

    useEffect(handleResize, [legend]);

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            popover={{
                                name: 'Datasets',
                                icon: 'fas fa-layer-group',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            {validDatasets.map((dataset) => (
                                                <Toggle
                                                    key={dataset.id}
                                                    title={dataset.name}
                                                    checked={viewedDatasetIds.includes(dataset.id)}
                                                    onChange={(v) =>
                                                        setViewedDatasetIds(
                                                            v
                                                                ? [...viewedDatasetIds, dataset.id]
                                                                : viewedDatasetIds.filter((id) => id !== dataset.id)
                                                        )
                                                    }
                                                />
                                            ))}
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() => {
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSampleCount,
                                                            tabId
                                                        )
                                                    );
                                                }}
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="datasets"
                        />
                        <HeaderButton
                            popover={{
                                name: 'Resolution & Samples',
                                icon: 'fas fa-ruler-horizontal',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            <Slider
                                                title="Samples"
                                                value={newSampleCount}
                                                min={10}
                                                max={1000}
                                                step={10}
                                                onChange={(v) => setNewSampleCount(v)}
                                            />
                                            <Slider
                                                title="Resolution"
                                                value={Number.parseFloat(
                                                    (horizontalLength / newSampleCount).toPrecision(3)
                                                )}
                                                min={horizontalLength / 1000}
                                                max={horizontalLength / 10}
                                                step={0.1}
                                                onChange={(v) => setNewSampleCount(Math.round(horizontalLength / v))}
                                            />
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() =>
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSampleCount,
                                                            tabId
                                                        )
                                                    )
                                                }
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="samples"
                        />
                        <HeaderButton
                            toggle={{
                                name: 'Show Legend',
                                icon: 'fas fa-list-ul',
                                checked: legend,
                                onChange: (v) => setLegend(v),
                            }}
                        />
                    </>
                }
                center={undefined}
                right={undefined}
            />
            <div ref={containerRef} style={{ height: '100%' }}>
                <div className="chartContainer" ref={containerRef}>
                    {legend ? <Legend /> : undefined}
                    {options ? (
                        <ReactECharts
                            ref={chartRef}
                            option={options}
                            style={{ height: '100%', flexGrow: '1' }}
                            notMerge
                        />
                    ) : undefined}
                </div>
            </div>
        </>
    );
};
export default ElevationProfile;
