import proj4 from 'proj4';
import { type Cache, GlobalCache } from '@giro3d/giro3d/core/Cache';
import type { LineString, FeatureCollection, Point, Feature, Position } from 'geojson';
import Fetcher from '@giro3d/giro3d/utils/Fetcher';
import Vector3Array from 'giro3d_extensions/Vector3Array';
import { CatmullRomCurve3, Curve, Vector2, Vector3 } from 'three';
import Source, { type LineCoordinates, Description, Property } from './Source';

/**
 * Reads data from a remote GeoJSON file.
 */
export default class GeojsonSource implements Source {
    private _featureCollection: FeatureCollection;

    private readonly _converter: proj4.Converter;

    private readonly _url: string;

    private readonly _cache: Cache;

    private readonly _cacheKey: string;

    private _description: Description;

    constructor(params: { url: string; crsIn: string; crsOut: string }) {
        this._url = params.url;
        this._cache = GlobalCache;
        this._cacheKey = params.url;
        if (params.crsIn !== params.crsOut) {
            this._converter = proj4(params.crsIn, params.crsOut);
        }
    }

    async initialize() {
        if (this._featureCollection == null) {
            this._featureCollection = await Fetcher.json(this._url);
        }
    }

    private createDescription(): Description {
        const properties: Map<string, Property> = new Map();

        const propertyNames = this.getPropertyNames();

        for (const prop of propertyNames) {
            const minmax = this.getPropertyRange(prop);

            properties.set(prop, {
                name: prop,
                min: minmax.x,
                max: minmax.y,
            });
        }

        return {
            properties,
        };
    }

    private getPropertyRange(name: string): Vector2 {
        const values = this.getPropertySync(name);

        let min = +Infinity;
        let max = -Infinity;

        for (let i = 0; i < values.length; i++) {
            const array = values[i];

            for (let j = 0; j < array.length; j++) {
                const v = array[j];
                min = Math.min(min, v);
                max = Math.max(max, v);
            }
        }

        return new Vector2(min, max);
    }

    private getPropertyNames(): string[] {
        const result: Set<string> = new Set();

        const features = this._featureCollection.features;
        const feature = features[0];
        const keys = Object.keys(feature.properties);
        keys.forEach((key) => {
            const f = features.find((p) => p.properties[key] !== null);
            if (!f) return;
            const prop = f.properties[key];
            const value = Array.isArray(prop) ? prop[0] : prop;
            if (typeof value === 'number') {
                result.add(key);
            }
        });

        const resultArray = [...result].sort();

        return resultArray;
    }

    async getDescription(): Promise<Description> {
        if (!this._description) {
            await this.initialize();
            this._description = this.createDescription();
        }

        return this._description;
    }

    private get isPointCollection() {
        return this._featureCollection.features[0].geometry.type === 'Point';
    }

    private getPosition(position: Readonly<Position>): Position {
        let result: Position;
        // Reproject to target CRS if needed
        if (this._converter) {
            result = this._converter.forward(position.slice());
        } else {
            result = position.slice();
        }

        // Add the z-coordinate if missing
        if (result.length === 2) {
            result.push(0);
        }
        return result;
    }

    private readLineString(lineString: LineString, absolute: boolean): LineCoordinates {
        const points = new Vector3Array({ length: lineString.coordinates.length });
        const first = this.getPosition(lineString.coordinates[0]);
        const origin = absolute ? new Vector3(0, 0, 0) : new Vector3(first[0], first[1], first[2]);

        for (let i = 0; i < lineString.coordinates.length; i++) {
            const p = this.getPosition(lineString.coordinates[i]);
            const x = p[0] - origin.x;
            const y = p[1] - origin.y;
            const z = p[2] - origin.z;
            points.set(i, x, y, z);
        }

        return { points, origin };
    }

    private readPoints(features: Feature[], absolute: boolean): LineCoordinates {
        const points = new Vector3Array({ length: features.length });
        const first = this.getPosition((features[0].geometry as Point).coordinates);
        const origin = absolute ? new Vector3(0, 0, 0) : new Vector3(first[0], first[1], first[2]);

        for (let i = 0; i < features.length; i++) {
            const p = this.getPosition((features[i].geometry as Point).coordinates);
            const x = p[0] - origin.x;
            const y = p[1] - origin.y;
            const z = p[2] - origin.z;
            points.set(i, x, y, z);
        }

        return { points, origin };
    }

    async getCurves(): Promise<Curve<Vector3>[]> {
        await this.initialize();

        const key = `${this._cacheKey}-curves`;
        const cached = this._cache.get(key);
        if (cached) {
            return cached as Curve<Vector3>[];
        }

        const geometries = await this.getGeometries(true);
        const curves: Curve<Vector3>[] = [];
        let sizeBytes = 0;
        for (const geom of geometries) {
            const curve = new CatmullRomCurve3(geom.points.toArray());
            curves.push(curve);
            sizeBytes += curve.points.length * 3 * 4;
        }

        this._cache.set(key, curves, {
            size: sizeBytes,
        });

        return curves;
    }

    async getGeometries(absolute = false): Promise<LineCoordinates[]> {
        await this.initialize();

        const key = `${this._cacheKey}-geometries-${absolute ? 'a' : 'r'}`;
        const cached = this._cache.get(key);
        if (cached) {
            return cached as LineCoordinates[];
        }

        const result: LineCoordinates[] = [];

        let sizeBytes = 0;

        if (this.isPointCollection) {
            const points = this.readPoints(this._featureCollection.features, absolute);
            result.push(points);
            sizeBytes += points.points.buffer.byteLength;
        } else {
            for (let i = 0; i < this._featureCollection.features.length; i++) {
                const feature = this._featureCollection.features[i];
                const lineString = this.readLineString(feature.geometry as LineString, absolute);
                result.push(lineString);
                sizeBytes += lineString.points.buffer.byteLength;
            }
        }

        this._cache.set(key, result, {
            size: sizeBytes,
        });

        return result;
    }

    // eslint-disable-next-line class-methods-use-this
    private readLineStringProperty(feature: Feature, name: string): Float32Array {
        const property = feature.properties[name] as number[];
        if (!property) {
            throw new Error(`unknown property: ${name}`);
        }

        return new Float32Array(property) as Float32Array;
    }

    // eslint-disable-next-line class-methods-use-this
    private readPointProperties(features: Feature[], name: string): Float32Array {
        const result: number[] = [];
        for (let i = 0; i < features.length; i++) {
            const property = features[i].properties[name];
            result.push(property);
        }

        return new Float32Array(result) as Float32Array;
    }

    private getPropertySync(name: string): Float32Array[] {
        const result: Float32Array[] = [];

        if (this.isPointCollection) {
            const values = this.readPointProperties(this._featureCollection.features, name);
            result.push(values);
        } else {
            for (let i = 0; i < this._featureCollection.features.length; i++) {
                const feature = this._featureCollection.features[i];
                const values = this.readLineStringProperty(feature, name);
                result.push(values);
            }
        }

        return result as Float32Array[];
    }

    async getPropertyValues(): Promise<Map<string, Float32Array[]>> {
        await this.initialize();

        const map = new Map<string, Float32Array[]>();
        for (const propName of this.getPropertyNames()) {
            const value = this.doGetPropertyValue(propName);
            map.set(propName, value);
        }

        return map;
    }

    // eslint-disable-next-line class-methods-use-this
    private doGetPropertyValue(name: string): Float32Array[] {
        const key = `${this._cacheKey}-prop-${name}`;
        const cached = this._cache.get(key);
        if (cached) {
            return cached as Float32Array[];
        }

        const result = this.getPropertySync(name);

        const cacheSize = result.map((b) => b.byteLength).reduce((prev, cur) => prev + cur);

        this._cache.set(key, result, {
            size: cacheSize,
        });

        return result;
    }

    async getPropertyValue(name: string): Promise<Float32Array[]> {
        await this.initialize();

        return this.doGetPropertyValue(name);
    }
}
